import React from "react";
import { Route } from "react-router-dom";
import Home from "./pages/home.js";
import HeaderBar from "./pages/headerBar.js";
import Trial from "./pages/trial.js";
import Navbar from "./pages/navbar.js";
import logo from "./icon/good2swim-logo.png";
import GetStudentTrialList from "./pages/getStudentTrialList.js";
import StudentListView from "./pages/studentListView.js";
import SingleStudenTrialView from "./pages/singleStudentTrialView.js";
import GetStudentList from "./pages/getStudentList.js";
import SingleStudentView from "./pages/singleStudentView.js";
import SingleClassView from "./pages/singleClassView.js";
import SingleClassViewForCoach from "./pages/singleClassViewForCoach";
import GetDailyTable from "./pages/getDailyTable.js";
import GetWeekTable from "./pages/getWeekTable.js";
import StudentEdit from "./pages/studentEdit.js";
import StudentAdd from "./pages/studentAdd.js";
import GetClassList from "./pages/getClassList.js";
import classEdit from "./pages/classEdit.js"
import classAdd from "./pages/classAdd.js"
import BeforeSingleClassView from "./pages/beforeSingleClassView.js"
import StaffListView from "./pages/staffListView.js"
import Login from "./pages/login.js"
import StaffEdit from "./pages/staffEdit.js"
import StaffAdd from "./pages/staffAdd.js"
import GetClassTemplateList from "./pages/getClassTemplateList.js"
import CanvasPad from "./pages/canvasPad.js"
import orderAdd from "./pages/orderAdd.js"
import orderAddTrial from "./pages/orderAddTrial.js"
import OrderListView from "./pages/orderListView.js"
import GetDailySignTable from"./pages/getDailySignTable.js"
import GetDailySignSingleClassListTable from"./pages/getDailySignSingleClassListTable.js"
import ProductOrderAdd from "./pages/productOrderAdd.js"
import ProductListView from "./pages/productListView.js"
import MakeRecordFromGetClassList from "./pages/makeRecordFromGetClassList"
import homeLine from "./pages/homeLine"
import studentTrialFollow from "./pages/studentTrialFollow.js"
import allClassCancel from "./pages/allClassCancel.js"
import orderView from "./pages/orderView.js"
import orderExtend from "./pages/orderExtend.js"
import reportAdd from "./pages/reportAdd"
import GetReportList from "./pages/getReportList"
import ReportEdit from "./pages/reportEdit"
import templateClassAdd from "./pages/templateClassAdd"
import templateClassEdit from "./pages/templateClassEdit"
import singleStudentViewForActionHistory from "./pages/singleStudentViewForActionHistory.js"
import singleClassFeedBack from "./pages/singleClassFeedBack"
import singleClassFeedBackReadOnly from "./pages/singleClassFeedBackReadOnly"
import coachClassListThisMonth from "./pages/coachClassListThisMonth"
import singleStudentViewForFreePractice from "./pages/singleStudentViewForFreePractice.js"

let env = process.env.NODE_ENV;
//gcloud builds submit --tag asia.gcr.io/good2swim-os3/signpad

function App() {
  return (
    <div className="p-1  container ">
      <div id="headerBar" className="fixed-top">
        <HeaderBar />
      </div>
      <Route path="/templateClassEdit/:classDBID" exact component={templateClassEdit} />
      <Route path="/coachClassListThisMonth/:staffDBID" exact component={coachClassListThisMonth} />
      <Route path="/templateClassAdd" exact component={templateClassAdd} />
      <Route path="/studentTrialFollow/:studentDBID" exact component={studentTrialFollow} />
      <Route path="/homeLine" exact component={homeLine} />
      <Route path="/ProductOrderAdd" exact component={ProductOrderAdd} />
      <Route path="/ProductListView" exact component={ProductListView} />
      <Route path="/orderAdd/:studentDBID" exact component={orderAdd} />
      <Route path="/orderView/:orderDBID" exact component={orderView} />
      <Route path="/orderAddTrial/:studentDBID" exact component={orderAddTrial} />
      <Route path="/allClassCancel/:studentDBID" exact component={allClassCancel} />
      <Route path="/orderExtend/:orderDBID" exact component={orderExtend} />
      <Route path="/OrderListView/" exact component={OrderListView} />
      <Route path="/GetReportList/" exact component={GetReportList} />
      <Route path="/reportAdd/" exact component={reportAdd} />
      <Route path="/reportEdit/:reportDBID" exact component={ReportEdit} />
      {/* 簽名測試 */}
      <Route path="/CanvasPad/:classRecordDBID" exact component={CanvasPad} />  
      <Route path="/GetDailySignTable" exact component={GetDailySignTable} />  
      <Route path="/GetDailySignSingleClassListTable/:classDBID" exact component={GetDailySignSingleClassListTable} />  
      <Route path="/staffListView" exact component={StaffListView} />
      <Route path="/staffEdit/:staffDBID" exact component={StaffEdit} />
      <Route path="/staffAdd" exact component={StaffAdd} />
      <Route path="/Home" exact component={GetDailySignTable} />
      <Route path="/Login" exact component={Login} />
      <Route path="/Logout" exact component={()=>{ sessionStorage.clear()
      window.location.href="/Login" }} />
      <Route path="/StudentListView" exact component={StudentListView} />
      <Route
        path="/SingleStudentTrialView/:studentDBID"
        exact
        component={SingleStudenTrialView}
      />
       <Route path="/MakeRecordFromGetClassList/:studentDBID" exact component={MakeRecordFromGetClassList} />
      <Route path="/" exact component={GetDailySignTable} />
      <Route path="/classEdit/:classDBID" exact component={classEdit} />
      <Route path="/classAdd/:classDate" exact component={classAdd} />
      <Route path="/StudentAdd" exact component={StudentAdd} />
      <Route path="/trial" exact component={Trial} />
      <Route
        path="/getStudentTrialList"
        exact
        component={GetStudentTrialList}
      />
      <Route path="/studentEdit/:studentDBID" exact component={StudentEdit} />
      <Route path="/getStudentList/:togo" exact component={GetStudentList} />
      <Route path="/getClassList/:togo" exact component={GetClassList} />
      <Route
        path="/singleClassView/:classDBID"
        exact
        component={SingleClassView}
      />
      <Route
        path="/singleClassViewForCoach/:classDBID"
        exact
        component={SingleClassViewForCoach}
      />

<Route
        path="/singleClassFeedBackReadOnly/:classRecordDBID"
        exact
        component={singleClassFeedBackReadOnly}
      />
        {/* <Route
        path="/SingleClassViewAfter/:classDBID"
        exact
        component={SingleClassView}
      /> */}
      <Route
        path="/SingleStudentView/:studentDBID"
        exact
        component={SingleStudentView}
      />
     
     <Route
        path="/singleStudentViewForActionHistory/:studentDBID"
        exact
        component={singleStudentViewForActionHistory}
      />
       <Route
        path="/singleStudentViewForFreePractice/:studentDBID"
        exact
        component={singleStudentViewForFreePractice}
      />
      <Route path="/GetClassTemplateList" exact component={GetClassTemplateList} />
      <Route path="/GetDailyTable/:getDate" exact component={GetDailyTable} />
      <Route path="/GetWeekTable/:getDate" exact component={GetWeekTable} />
      <Route path="/singleClassFeedBack/:classRecordDBID" exact component={singleClassFeedBack} />
      <div id="footerBar" className="fixed-bottom">
        <Navbar env={env} />
      </div>
    </div>
  );
}

export default App;
