function getUrl(){
    var url = null;

    let env = process.env.NODE_ENV;
    if (env == "production") {
      url = "https://golang-server-oi3as7ovga-de.a.run.app";
    } else {
      url = "http://localhost:8080";
    }

    return url;
}


export default getUrl;