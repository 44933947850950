import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from "../function/getUrl.js"
import pencilIcon from "../icon/pencil.svg";

const token = sessionStorage.getItem("token")
const url = getUrl()
const imgStyle = {
  width: '20px',
  height: '20px',
};


//大量排課
const MakeRecordFromGetClassList = () => {
  checkLogin()
  const param = useParams() //功能項目
  const studentDBID = param.studentDBID //指定學生編號
  const [classAmount, setClassAmount] = useState(0)  //暫時 假設學生可排10堂 （購課-缺課-完課-已劃課 且 身分為有效期限前）
  const today = getDay(0, "-")
  const [studentClassLastDate, setStudentClassLastDate] = useState(today)
  let title = "大量排課"
  let goLink = ""
  let getDataURL = `${url}/appGet/getClassByContext`
  const [studentData, setStudentData] = useState({})



  //進度條
  let loadingState = <>
    <h5 className="text-info mt-5">資料載入中...</h5>
    <div className="progress w-50">
      <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
    </div>
  </>
  const [classList, setClassList] = useState(null)
  const [classData, setClassDate] = useState({})
  const [pickUpClassAmount, setPickUpClassAmount] = useState(0) //已選取劃位的數字統計

  function hanedleState(e) {

    handleSearch()
  }

  function getSelect(e) {

    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        return optionFollow[i].value;
      }
    }
    return "";
  }

  // function countCheckBox(){

  //   let x=document.getElementsByTagName("input")



  //   for (let y in x){
  //     if (x[y].checked){
  //       console.log(x[y].value)
  //      tempCount++
  //      if (tempCount >=classAmount){
  //       alert('劃位數超標囉！')
  //      return false
  //     }

  //     }
  //   }

  //   setPickUpClassAmount(tempCount)
  //  return true
  // }
  //  檢查該堂是否已劃過位
  function checkBooking(classDBID) {
    let bookingData = studentData.classList.find(item => {
      return (item.classDBID == classDBID)
    })

    if (bookingData) {
      return true
    }

    return false
  }
  //  檢查該日是否已劃過位
  function checkBookingDate(classDate) {
    let bookingData = studentData.classList.find(item => {
      return (item.classDate == classDate)
    })

    if (bookingData) {
      return true
    }

    return false
  }



  //檢查是否仍有位子:已劃位 小於 limit
  function checkLimit(classDBID) {
    // console.log(classData)
    let classSingleData = classData.find(item => {

       console.log(item.classDBID,classDBID,item.limit,item.booking,item.available)
      return (item.classDBID == classDBID && (item.limit > item.booking && item.available == "true") && (item.classDate <= studentClassLastDate))

    })


    if (classSingleData) {
      // console.log('classSingleData',classSingleData)
      return true
    }
    return false
  }

  function allBooking() {
    let x = document.getElementsByTagName("input")

    let checkedArray = []
    let count = 0
    for (let y in x) {

      // if (x[y].checked != undefined) {
      //   console.log(x[y].checked)
      //   console.log(x[y].value)
      //   //有空位且 有開課
      //   if (x[y].id.indexOf("checkBox") != -1 && count < classAmount && checkLimit(x[y].value) && x[y].checked == true) {
      //     count++
      //     console.log(x[y].value)
      //     checkedArray.push(x[y].value)

      //   } 


      // }


      if (x[y].checked == true) {
        console.log(classAmount,count,x[y].checked,x[y].value,checkLimit(x[y].value))
      
        //有空位且 有開課
        if ( count < classAmount && checkLimit(x[y].value)) {
          count++
          console.log(x[y].value)
          checkedArray.push(x[y].value)

        } 


      }

    }
    console.log(checkedArray)

    fetch(`${url}/appGet/makeClassRecordForALot?studentDBID=${studentDBID}&classDBID=${checkedArray}&token=${token}`)
    window.location.href=`/SingleStudentView/${studentDBID}`
     
  }

  function allCheck() {
    let x = document.getElementsByTagName("input")

    let checkedArray = []
    let count = 0
    for (let y in x) {

      if (x[y].checked != undefined) {
        //有空位且 有開課
        if (x[y].id.indexOf("checkBox") != -1 && count < classAmount && checkLimit(x[y].value)) {
          count++
          x[y].checked = true
          checkedArray.push(x[y].value)
        } else {
          x[y].checked = false
        }


      }
      // console.log(checkedArray)
      // console.log(checkedArray.length)

    }
  }


  function handleSearch(e) {
    console.log(token)
    setClassList(loadingState)
    let getAge = getSelect(document.getElementById('searchAge'))
    let getDate = document.getElementById('searchDate').value
    let getWeek = getSelect(document.getElementById('searchWeek'))
    let getTime = document.getElementById('searchTime').value
    // let goUrl = `${getDataURL}?token=${token}`
    // if (getDate == "" && getAge == "") {
    //   goUrl = `${getDataURL}?token=${token}`
    // } else if (getAge == "") {
    //   goUrl = `${getDataURL}?date=${getDate}&token=${token}`
    // } else if (getDate == "") {
    //   goUrl = `${getDataURL}?age=${getAge}&token=${token}`
    // } else {
    //   goUrl = `${getDataURL}?age=${getAge}&date=${getDate}&token=${token}`
    // }
    console.log("getDate:", getDate)
    let goUrl = `${getDataURL}?age=${getAge}&date=${getDate}&token=${token}`
    console.log('goUrl', goUrl)
    let classArray = []

    fetch(goUrl).then((res) => {
      return res.json();
    }).then(json => {

      json.sort((a, b) => {

        var nameA = a.classTime;
        var nameB = b.classTime;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }


        return 0;
      })

      json.sort((a, b) => {

        var nameA = a.classDate;
        var nameB = b.classDate;
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }


        return 0;
      })



      var day_list = ['日', '一', '二', '三', '四', '五', '六'];
      let count = 0
      for (let x in json) {

        // if (json[x].available == "true") {
        //   json[x].available = "Yes"
        // } else {
        //   json[x].available = "No"
        // }

        var dateWeek = new Date(json[x]['classDate']).getDay()
        var week = day_list[dateWeek]
        // console.log(studentData)
        // console.log("getTime",getTime,"json[x]['classTime']",json[x]['classTime'],studentData.studentClassLastDate)
        if ((getWeek == "" || getWeek == week) && (getTime == "" || getTime == json[x]['classTime']) && json[x]['classDate'] <= studentData.studentClassLastDate && !checkBooking(json[x]['classDBID'])) {
          count += 1
          //如果劃位超過上限，則顯示紅色警示
          let textCSS = "text-muted"
          if (json[x]['reservation'] > json[x]['limit'] || checkBookingDate(json[x]['classDate'])) {
            textCSS = "text-danger text-weight-bold"
          }


          let singleClass =
            <tr onClick={() => {
              //處理按下的資料


              const checkBoxElement = document.getElementById(`checkBox-${x}`)
              checkBoxElement.checked = !checkBoxElement.checked


            }} className={textCSS} style={{ textDecoration: 'none' }}>
              <th scope="row">{count}</th>
              <td scope="col">
                <input type="checkbox" id={`checkBox-${x}`} className="checkBoxText" value={json[x]['classDBID']} class="form-check-input" />

              </td>
              <td>{json[x]['classDBID']}</td>
              <td>{json[x]['classDate']}</td>
              <td>{week}</td>
              <td>{json[x]['classTime']}</td>
              <td>{json[x]['age']}</td>
              <td>{json[x]['available']}</td>
              <td>{json[x]['limit']}</td>
              <td>{json[x]['booking']}</td>

            </tr>

          classArray.push(singleClass)



        }





      }

      setClassDate(json)
    }).then(() => {
      setClassList(classArray)
      // document.getElementById('searchAge').value = ""
      // document.getElementById('searchDate').value = ""
      // document.getElementById('searchWeek').value = ""
      // document.getElementById('searchWeek').disabled = true
      let test = document.getElementById('searchAge').options
      // console.log(test[0])
      // console.log(document.getElementById('searchAge').options)
    })
  }

  function ChangeDisabled(e) {
    let value = document.getElementById('searchAge').value
    if (value == "") {
      document.getElementById('searchWeek').disabled = true;　// 變更欄位為可用
      document.getElementById('searchTime').disabled = true;　// 變更欄位為可用
    } else {
      document.getElementById('searchWeek').disabled = false;　// 變更欄位為禁用
      document.getElementById('searchTime').disabled = false;
    }
  }

  function getStudentData(studentDBID) {
    return fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setStudentData(json)
        setStudentClassLastDate(json.studentClassLastDate)
        setClassAmount(json.classBalanceForBooking)

      })
  };
  useEffect(() => {

    getStudentData(studentDBID)
    // handleSearch()
    document.getElementById('searchDate').value = today

  }, [])

  return (
    <>
      <div className="container text-center">
        <div className="card alert-info p-2 " >
          <div className="container vw-75" style={{ maxWidth: "500px" }}>
            <h5 className="m-1">{title} {studentData.name}     </h5>
            <h6 className="m-1 text-muted">可劃位堂數:{classAmount}堂     期限：{studentClassLastDate}</h6>
            <h6 className="m-1 text-muted">請先挑選課程</h6>
            <div className="card-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"> 日期</span>
                </div>
                <input type="date" className="form-control " id="searchDate" aria-label="Username" aria-describedby="basic-addon1" onChange={
                  (e) => {

                    if (e.target.value < today) {
                      alert('大量排課日期起始不得小於今天')
                      e.target.value = today
                    }

                  }
                } />


              </div>



              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    年紀
                  </span>
                </div>

                <select
                  id="searchAge"

                  className="custom-select"
                  required

                  onChange={ChangeDisabled}
                >
                  <option selected value="">全部</option>
                  <option value="1-2Y">1-2Y </option>
                  <option value="2-3Y">2-3Y </option>
                  <option value="3-7Y">3-7Y </option>
                  <option value="7-12Y">7-12Y </option>
                  <option value="2M">2M </option>
                  <option value="3-6M">3-6M </option>
                  <option value="6-12M">6-12M </option>
                  <option value="體驗">體驗 </option>
                  <option value="暑期班">暑期班 </option>
                  <option value="水中攝影">水中攝影 </option>
                  <option value="自由練習">自由練習 </option>
                  <option value="備用">備用 </option>

                </select>
              </div>

              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    星期
                  </span>
                </div>

                <select
                  id="searchWeek"
                  className="custom-select"
                  disabled
                >
                  <option selected value="">全部</option>
                  <option value="日">日 </option>
                  <option value="一">一 </option>
                  <option value="二">二 </option>
                  <option value="三">三 </option>
                  <option value="四">四 </option>
                  <option value="五">五 </option>
                  <option value="六">六 </option>
                </select>
              </div>

              <div className="input-group mt-1">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-primary"
                    id="validationTooltipUsernamePrepend"
                  >
                    時間
                  </span>
                </div>

                <input
                  id="searchTime"
                  type="number"
                  className="form-control"
                  placeholder="輸入時間 EX：0910"
                  disabled
                />

              </div>
            </div>
            <div className="w-100 container ">
              <button className="btn alert-dark ml-1 text-mute" style={{ width: "40%" }} onClick={() => {
                window.location.href = `/MakeRecordFromGetClassList/${studentDBID}`
              }} ><img src={reloadIcon} style={imgStyle} /> 清除</button>
              <button className="btn btn-info ml-1" onClick={handleSearch} style={{ width: "40%" }} ><img src={searchIcon} style={imgStyle} /> 送出查詢</button>


            </div>
            <div className="w-100 container mt-2 ">

              <button className="btn btn-warning ml-1" onClick={allCheck} style={{ width: "40%" }}><img src={pencilIcon} style={imgStyle} /> 自動勾選前 {classAmount}堂</button>
              <button className="btn btn-danger ml-1" onClick={allBooking} style={{ width: "40%" }} ><img src={pencilIcon} style={imgStyle} /> 送出劃位 </button>

              <p className="mt-2" >註：① 自動勾選只勾選「有開課且仍有空位的課程」<br /> ②當日已有劃位記錄則顯示紅色警示</p>

            </div>


            <div>
            </div>

          </div>

        </div>

        <hr />


        <table class="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="row">#</th>
              <th scope="col">選取</th>
              <th scope="col">編號</th>
              <th scope="col">日期</th>
              <th scope="col">星期</th>
              <th scope="col">時間</th>
              <th scope="col">年紀</th>
              <th scope="col">是否開課</th>
              <th scope="col">上限</th>
              <th scope="col">劃位</th>

            </tr>
          </thead>
          <tbody className="text-center">
            {classList}

          </tbody>
        </table>
      </div>
    </>
  )

}

export default MakeRecordFromGetClassList;