//計算學生劃位狀態
import React, {
    useState,
    useEffect,
    useCallback,
    updateState,
    useReducer,
    useContext,
    createContext,
  } from "react";

import getDay from "../function/getDay.js";
import dateDiff from "../function/dateDiff.js";
// studentList : 課堂學員名單
// classDate：課程資訊
//setViewUpdateState ：本堂更動已是否 Hook
//setStudentList ：hook 學員清單
const getStudentState = (studentList, classDate,setViewUpdateState,setStudentList) => {


    studentList.sort((a, b) => {
      var nameA = a.state;
      var nameB = b.state;
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    function handleClassState(e) {
      let value = e.target.value;
      let classRecordDBID = e.target.id;
      for (let x in studentList) {
 

        if (studentList[x]["classRecordDBID"] == classRecordDBID) {
          console.log(studentList[x]["updateFlag"]);
          studentList[x]["updateFlag"] = true;
          studentList[x].studentState = value;
          console.log(
            studentList[x]["updateFlag"],
            studentList[x].studentState
          );
        }
      }
      //setUpdateState(true)

      setViewUpdateState(true);
      setStudentList(studentList);
    }

    studentList.map((data, index) => {
      studentList[index]["originalStudentState"] = data["studentState"];
      let today = getDay(0, "-");
      //let classDate = classData.classDate
      console.log("classDate", classDate);
      let dayDiff = dateDiff(classDate, today); // 日期差

      let selectPart = "";


      //請假証明文件 如果為true 則可以啟用 融通取消
      let proveOption = null;
      if (data.prove != "") {
        proveOption = <option value="融通取消">融通取消</option>;
      }

      switch (data["studentType"]) {
        case "正式":
          if (dayDiff > 1) {
            //兩天前 自由劃位/取消/候補

            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                    <option value="候補">候補</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;
              case "候補":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="候補">候補</option>
                    <option value="劃位">劃位</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;
              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option Value="取消">取消</option>
                    <option value="劃位">劃位</option>
                    <option value="候補">候補</option>
                  </select>
                );
                break;

              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                    <option value="候補">候補</option>
                  </select>
                );
                break;
            }
          } else if (dayDiff > 0) {
            //1-2天 自由劃位/融通取消/候補
            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                    {/* 暫時開發 融通取消 */}
                    <option value="融通取消">融通取消</option>
                    {proveOption}
                  </select>
                );
                break;
              case "候補":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="候補">候補</option>
                    <option value="劃位">劃位</option>
                    <option defaultValue="取消">取消</option>
                  </select>
                );
                break;
              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="取消">取消</option>
                    <option value="劃位">劃位</option>
                    <option value="候補">候補</option>
                  </select>
                );
                break;
              case "融通取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="融通取消">融通取消</option>
                    <option value="劃位">劃位</option>
                    <option value="候補">候補</option>
                  </select>
                );
                break;
              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValuevalue="劃位">劃位</option>
                    <option value="候補">候補</option>
                  </select>
                );
                break;
            }
          } else if (dayDiff == 0) {
            // 當天 自由劃位/融通取消/缺課/完課
            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                   
                    <option value="完課">完課</option>
                    <option value="缺課">缺課</option>
                      {/* 暫時開發 融通取消 */}
                      <option value="融通取消">融通取消</option>
                    {proveOption}
                  </select>
                );
                break;
              case "候補":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="候補">候補</option>
                    <option Value="劃位">劃位</option>
                    <option value="取消">取消</option>
                    <option value="完課">完課</option>
                  </select>
                );
                break;
              case "完課":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="完課">完課</option>
                    {/* <option value="融通取消">融通取消</option> */}
                    <option value="缺課">缺課</option>
                    {proveOption}
                  </select>
                );
                break;
              case "缺課":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="缺課">缺課</option>
                    {/* <option value="融通取消">融通取消</option> */}
                    <option value="完課">完課</option>
                    <option defaultValue="缺課">缺課</option>
                  </select>
                );
                break;
              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option value="取消">取消</option>
                    <option Value="劃位">劃位</option>
                    <option Value="候補">候補</option>
                  </select>
                );
                break;

              case "融通取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option value="融通取消">融通取消</option>
                    <option Value="劃位">劃位</option>
                    <option Value="候補">候補</option>
                  </select>
                );
                break;

              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option Value="劃位">劃位</option>
                    <option defaultValue="候補">候補</option>
                    <option value="完課">完課</option>
                    <option value="缺課">缺課</option>
                  </select>
                );
                break;
            }
          } else {
            // 過期 融通取消/缺課/完課

            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="完課">完課</option>
                    <option value="缺課">缺課</option>
                    {proveOption}
                  </select>
                );
                break;
              case "候補":
                selectPart = null;
                // <select id={data['classRecordDBID']} className="custom-select" onChange={handleClassState}>
                //     <option defaultValue="候補">候補</option>
                //     <option value="完課">完課</option>
                // </select>
                break;
              case "完課":
                selectPart = null;
                // selectPart =
                //     <select id={data['classRecordDBID']} className="custom-select" onChange={handleClassState}>
                //         <option defaultValue="完課">完課</option>
                //         <option value="缺課">缺課</option>
                //     </select>
                break;
              case "缺課":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="缺課">缺課</option>
                    {/* <option value="融通取消">融通取消</option> */}
                    <option value="完課">完課</option>
                    {proveOption}
                  </select>
                );
                break;
              case "取消":
                selectPart = null;
                // <select id={data['classRecordDBID']} className="custom-select" onChange={handleClassState}>
                //     <option defaultValue="取消">取消</option>
                //     <option Value="完課">完課</option>
                // </select>
                break;

              default:
                selectPart = null;
                // <select id={data['classRecordDBID']} className="custom-select" onChange={handleClassState}>
                //     <option Value="劃位">劃位</option>
                //     <option defaultValue="候補">候補</option>
                //     <option value="融通取消">融通取消</option>
                //     <option value="完課">完課</option>
                //     <option value="缺課">缺課</option>
                // </select>
                break;
            }
          }
          break;
        case "體驗":
          if (dayDiff > 0) {
            // 自由劃位/融通取消/缺課/完課
            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;

              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="取消">取消</option>
                    <option Value="劃位">劃位</option>
                  </select>
                );
                break;

              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option Value="劃位">劃位</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;
            }
          } else if (dayDiff == 0) {
            // 自由劃位/融通取消/缺課/完課
            switch (data["studentState"]) {
              case "劃位":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                     <option Value="劃位">劃位</option>
                    <option value="取消">取消</option>
                    <option value="完課">完課</option>
                  </select>
                );
                break;
              case "完課":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="完課">完課</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;

              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="取消">取消</option>
                    <option value="劃位">劃位</option>
                    <option value="完課">完課</option>
                  </select>
                );
                break;

              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="劃位">劃位</option>
                    <option value="取消">完課</option>
                  </select>
                );
                break;
            }
          } else {
            // 過期 融通取消/缺課/完課

            switch (data["studentState"]) {
              
              
              case "完課":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="完課">完課</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;
              case "取消":
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="取消">取消</option>
                    <option value="完課">完課</option>
                  </select>
                );
                break;

              default:
                selectPart = (
                  <select
                    id={data["classRecordDBID"]}
                    className="custom-select"
                    onChange={handleClassState}
                  >
                    <option defaultValue="完課">完課</option>
                    <option value="取消">取消</option>
                  </select>
                );
                break;
            }
          }
          break;
      }
      console.log("data['signPic']", data["signPic"]);
      if (data["signPic"] == "") {
        data["select"] = selectPart;
      } else {
        data["select"] = <p>已完課</p>;
      }
    });
    //更新

    return studentList;
  };

  export default getStudentState;