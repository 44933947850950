import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
  createContext,
} from "react";

import { Link, useParams } from "react-router-dom";
import getUrl from "../function/getUrl.js"
import checkLogin from "../function/checkLogin.js"
const url = getUrl()
const token=sessionStorage.getItem("token")

const BeforeSingleClassView = () => {
  const param = useParams();
  const classDBID = param.classDBID;
checkLogin()


  fetch(`${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`)
  .then((res) => {
    return res.json();
  })
  .then((json) => {

    sessionStorage.setItem('studentList',JSON.stringify(json.studentList))
    sessionStorage.setItem('classData',JSON.stringify(json))
  
  }).then(()=>{
    window.location.href=`/SingleClassViewAfter/${classDBID}`
  })


  return (
    <>
<div className="container">
        <h5 className="text-info mt-5">資料載入中...</h5>
        <div className="progress m-5">
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
        </div>
   
        </div>
    </>
  );
};



export default BeforeSingleClassView;
