import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import SearchIcon from "../icon/search.svg";
import lotIcon from "../icon/sliders.svg";
import fingerTap from "../icon/tap.svg"

const FunBarClass = (props) => {
  let classDBID = props.classDBID;
  let classDate=props.classDate
  let toGoTodayClass="/GetDailyTable/"+classDate;
  let newOrder = "/newOrder/" + classDBID;
  let makeClassRecord = "/makeClassRecord/" + classDBID;
  let makeLotClassReocrd = "/makeLotClassReocrd/" + classDBID;
  const version = "V 3.0.1 ";
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };
  //menu Bar 元作
  return (
    <nav className="navbar navbar-light bg-info rounded-top py-1 ">
            <div className="mr-auto">
        <div
          className="navbar-brand btn"
          onClick={()=>{
            window.location.href=`/GetDailyTable/${classDate}`
          }}
          style={{ textAlign: "center" }}
        >
          <img src={classIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            {classDate} 本日課表
          </span>
        </div >
      </div>

      
      <div className="mr-auto">
        <div
          className="navbar-brand "
          
          onClick={()=>{
            window.location.href=`/classEdit/${classDBID}`
          }}
          style={{ textAlign: "center" }}
        >
          <img src={pencilIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            編輯課程
          </span>
        </div>
      </div>

      <div className="ml-auto">
        <div
          className="navbar-brand "

          onClick={()=>{
            window.location.href=`/SingleClassViewForCoach/${classDBID}`
          }}
         
          style={{ textAlign: "center" }}
        >
          <img src={pencilIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            學習進度維護
          </span>
        </div>
      </div>

     
      <div className="ml-auto">
        <div
          className="navbar-brand "

          onClick={()=>{
            window.location.href=`/GetDailySignSingleClassListTable/${classDBID}`
          }}
         
          style={{ textAlign: "center" }}
        >
          <img src={fingerTap} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            線上簽到
          </span>
        </div>
      </div>

      
    </nav>
  );
};

export default FunBarClass;
