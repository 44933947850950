import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from '../function/getUrl'
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
const url= getUrl()
const token=sessionStorage.getItem("token")
//快速查詢學生
const GetStudentTrialListForHome = (props) => {
 
  let param = useParams(); //功能項目
  let togo = param.togo;
  let title = "體驗報名處理";
  let goLink = "/SingleStudentTrialView/";
 
  //設計功能切換

  //進度條
  let loadingState = (
    <>
      <h5 className="text-info mt-5">資料載入中...</h5>
      <div className="progress w-50">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-info"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: "100%" }}
        ></div>
      </div>
    </>
  );
  const [studentList, setStudentList] = useState(loadingState);

  var imgStyle = {
    width: "20px",
    height: "20px",
  };
  function makeList(e) {
    setStudentList(loadingState);
    
  
    let studentArray = [];
    let name=""
    fetch(`${url}/appGet/getStudentByTrialFlag?name=${name}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {

      //   json.sort((a, b) => {
      //     var nameA = a.studentDBID;
      //     var nameB = b.studentDBID;
      //     if (nameA > nameB) {
      //         return -1;
      //     }
      //     if (nameA < nameB) {
      //         return 1;
      //     }
      //     return 0;
      // });

        for (let x in json) {
          let state="繼續追蹤"
         
          if (json[x].trialFlag=="false"){
            state="結案"
          }
          let age = birthdayToAge(json[x].birthday, "-");
          let singleStudent = (
            <tr
              onClick={() => {
                window.location.href = `${goLink}${json[x]["studentDBID"]}`;
              }}
              className="text-muted"
              style={{ textDecoration: "none" }}
            >
              <td>{json[x]["studentDBID"]}</td>
              <td>{json[x]["name"]}</td>
              <td>{json[x]["nickName"]}</td>
              <td>{json[x]["birthday"]}</td>
              <td>{age[0]}</td>
              <td>{json[x]["parent"]}</td>
              <td>{json[x]["createDate"].substring(0,10)}</td>
              <td>{state}</td>
            </tr>
          );
          if(state!="結案"){
            studentArray.push(singleStudent);
          }
          
        }
      })
      .then(() => {
        setStudentList(studentArray);
      });
  }


  useEffect(() => {
    makeList();
   
  }, []);

  return (
    <>

<div className="card mt-2" style={{ width: "18rem;" }}>
        <div className="card-body alert-success">
          <h5 className="card-title">體驗名單待處理 共計{studentList.length} 筆</h5>
          <div className="card-text alert-light rounded p-2">
          <table class="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="col">編號</th>
              <th scope="col">姓名</th>
              <th scope="col">暱稱</th>
              <th scope="col">生日</th>
              <th scope="col">年紀</th>
              <th scope="col">家長</th>
              <th scope="col">建立日期</th>
              <th scope="col">狀態</th>
            </tr>
          </thead>
          <tbody className="text-center">{studentList}</tbody>
        </table>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default GetStudentTrialListForHome;
