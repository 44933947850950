import React, {
  useState,
  useEffect,
} from "react";
import { Link, useParams } from "react-router-dom";
import getUrl from "../function/getUrl.js"
import checkLogin from "../function/checkLogin.js"
import StudentCard from '../components/studentCard.js'




const CanvasPad = () => {
  checkLogin()
  let param = useParams(); //功能項目
  const classRecordDBID = param.classRecordDBID;
  const token=sessionStorage.getItem("token")
  const [signPic, setSignPic] = useState(null)
  const [studentShow,setStudentShow]=useState(null)
  const [classData,setClassData]=useState({})
  const [updateState,setUpdateState]=useState(false)
  const url=getUrl()

  
  useEffect(async ()=>{

    
    const getData= async (classRecordDBID)=>{
      // let data=await getStudentData(classRecordDBID)
      let data=await fetch(`${url}/appGet/getClassRecord?token=${token}&classRecordDBID=${classRecordDBID}`).then((res)=>{
        return res.json()
      }).then((json)=>{
        console.log(json)
     
        return json
      })
      console.log(data)
      if (data.signPic!=""){
        document.getElementById("showArea").classList.add("invisible");
        document.getElementById("myCanvas").height="1px"
      }
      document.getElementById("showArea").style={"visibility":"hidden"}

      setClassData(data)
      
      let studentShowData=await fetch(`${url}/appGet/singleStudentView?studentDBID=${data.studentDBID}&token=${token}`)
            .then((res) => {
                return res.json();
            })
            .then((json) => {
              console.log("student",json)
                return (
                  <div className=" mb-2 text-left  mt-1"  >

                  <div className="alert alert-info">
                      <div className="row">
  
                          <div className="col text-left">
                              <h3 >{json.name} </h3>
                              
                          </div>
                          
  
                      </div>
                      <hr />
                      <h5 className>期限 :{json.studentClassLastDate}</h5>
                      <div>
                          <div className="row">
                          
                        
                              
                              <div className="col ">
                                  餘課數：{json.classBalance} 堂
                              </div>
                              <div className="col ">
                                  已劃位：{json.classBooking} 堂
                              </div>
                              <div className="col ">
                                  待劃位：{json.classBalanceForBooking} 堂
                         </div>
                          </div>
  
                      </div>
  
                  </div>
              </div>
                )

            })

            setStudentShow(studentShowData)

    }


     getData(classRecordDBID)
    
  },[])


  function mouseDown(e) {
    this.draw = true;
    this.ctx = this.getContext("2d");
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 3;

    var o = this;
    this.offsetX = this.offsetLeft;
    this.offsetY = this.offsetTop;
    setUpdateState(true)
    while (o.offsetParent) {
      o = o.offsetParent;
      this.offsetX += o.offsetLeft;
      this.offsetY += o.offsetTop;
    }

    this.ctx.beginPath();
    this.ctx.moveTo(e.pageX - this.offsetX, e.pageY - this.offsetY);
  }

  function mouseMove(e) {
    if (this.draw) {
      this.ctx.lineTo(e.pageX - this.offsetX, e.pageY - this.offsetY);
      this.ctx.stroke();
    }
  }

  function mouseUp(e) {
    this.draw = false;
    
  }

  function touchStart(e) {
    setUpdateState(true)
    this.draw = true;
    this.ctx = this.getContext("2d");
    this.touch = e.targetTouches[0];
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 2;

    var o = this;
    this.offsetX = this.offsetLeft;
    this.offsetY = this.offsetTop;

    while (o.offsetParent) {
      o = o.offsetParent;
      this.offsetX += o.offsetLeft;
      this.offsetY += o.offsetTop;
    }

    this.ctx.beginPath();
    this.ctx.moveTo(this.touch.pageX - this.offsetX, this.touch.pageY - this.offsetY);
    e.preventDefault();
  }

  function touchMove(e) {
    this.touch = e.targetTouches[0];
    if (this.draw) {
      this.ctx.lineTo(this.touch.pageX - this.offsetX, this.touch.pageY - this.offsetY);
      this.ctx.stroke();
    }
    e.preventDefault();
  }

  function touchEnd(e) {
    this.draw = false;
    e.preventDefault();
  }

  function clearPad() {
    var canvas = document.querySelector('#myCanvas');
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  window.addEventListener('load', function () {
    var canvas = document.querySelector('#myCanvas');

    canvas.addEventListener('mousedown', mouseDown);
    canvas.addEventListener('mousemove', mouseMove);
    canvas.addEventListener('mouseup', mouseUp);

    canvas.addEventListener('touchstart', touchStart);
    canvas.addEventListener('touchmove', touchMove);
    canvas.addEventListener('touchend', touchEnd);
  });



  function rollBackSign() {

    var canvas = document.getElementById("myCanvas");
 
    var formData = new FormData();
    formData.append("classRecordDBID", classRecordDBID);
    formData.append("signPicDBID",classData.signPicDBID);
    formData.append("studentState",'劃位');
    formData.append("token",token)
    fetch(`${url}/appPost/deleteSignPic`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(()=>{
        alert(`${classData.name}退回簽到完成`)
        window.location.href=`/GetDailySignSingleClassListTable/${classData.classDBID}`
      })
   
  
    
   
  }

  const ComfirmBtn=()=>{
   
      if (classData.signPic==""){
        return  <button id="comfirmSign" className="btn btn-danger m-1  p-2  " style={{ width: "80%" }} onClick={getSrc}>完成簽到</button>
                
      }else{
        return   <button  id="rollBackSign" className="btn btn-dark m-1  p-2  " style={{ width: "80%" }} onClick={rollBackSign}>退回簽到</button>
      }
   
  }


  function getSrc() {

    var canvas = document.getElementById("myCanvas");
    var dataURL = canvas.toDataURL();
    setSignPic(dataURL)
    console.log("updateState",updateState)
    if (!updateState){
      alert('尚未簽名')
      return 
    }

    var formData = new FormData();
    formData.append("classRecordDBID", classRecordDBID);
    formData.append("signPic",dataURL);
    formData.append("studentState",'完課');
    formData.append("token",token)
    fetch(`${url}/appPost/makeSignPic`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(()=>{
        alert(`${classData.name}完成簽到`)
        window.location.href=`/GetDailySignSingleClassListTable/${classData.classDBID}`
      })
   
  
    
   
  }
  return (
    <> 
    
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1">{classData.name} 學員上課簽到</h5>
        </div>
        <div className="mt-2">
       
          <div className="card mt-2 ">
            <div className="card-header alert-info">課程資料區</div>
            <div className="card-body ">
             <div className="m-1">
                {studentShow}
             </div>
           
              <div className="m-1">
                {/* <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      學員
                      </span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    disabled
                    value={classData.name}
                  />
                </div> */}
              </div>


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      日期
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classDate"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    disabled
                    value={classData.classDate}
                  />
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      時間
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    disabled
                    value={classData.classTime}
                  />
                </div>
              </div>

              <div className="m-1" >
                <h5 className="text-muted p-1">簽名版</h5>
                <div className="border border-info rounded card-body text-center" >
                <div id="showArea" >
                  <canvas  className="border   border-info rounded " id="myCanvas" width="360" height="240" style={{ border: "gray 1px solid" }}></canvas>
                  </div>
                  <hr/>
                  <img src={classData.signPic} />
                 
                 <ComfirmBtn />
                
                 
                  <div className="btn btn-warning mt-2 m-1  p-2 " style={{ width: "80%" }}  onClick={()=>{
                    window.location.href=`/GetDailySignSingleClassListTable/${classData.classDBID}`
                  }}>取消</div>
                 
              
                  
                 
                 

                  
                </div>
              </div>


            </div>
          </div>







        </div>
      </div>
    </>
  )
}

export default CanvasPad;