import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
  createContext,
} from "react";
import { createStore } from "redux";
import { Link, useParams } from "react-router-dom";
import getUrl from "../function/getUrl.js";
import cancelIcon from "../icon/cancelWhite.svg";
import classIcon from "../icon/swimClass.svg";
import saveIcon from "../icon/save.svg";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import birthdayToAge from "../function/birthdayToAge.js";
import getDay from "../function/getDay.js";
import dateDiff from "../function/dateDiff.js";
import pencil_gray from "../icon/pencil_gray.svg";
import pencilIcon from "../icon/pencil.svg";
import getStudentState from "../function/getStudentState.js" //處理學員劃課狀態


var imgStyle = {
  width: "30px",
  height: "30px",
};

const url = getUrl();
const token = sessionStorage.getItem("token");
//主元件
const ClassStudentListViewComponent = (props) => {
  const classDBID = props.classDBID;
  const [studentList, setStudentList] = useState([]);
  const [viewUpdateState, setViewUpdateState] = useState(false);
  const [classData, setClassData] = useState({});
  const [classDate, setClassDate] = useState(null);
  const [editBtn,setEditBtn]=useState(null)

  useEffect(() => {

    //取得簽名與請假証明
    const getClassRecordSignPicAndProveData = (classDBID) => {
      return fetch(
        `${url}/appGet/getClassRecordSignPicAndProve?classDBID=${classDBID}&token=${token}`
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return json;
        });
    };

    const getStudentData = (classDBID) => {
      return fetch(
        `${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          return json;
        });
    };


    const goGetInit = async (classDBID) => {
      let signPicAndProveData = await getClassRecordSignPicAndProveData(classDBID);
      let data = await getStudentData(classDBID);
      setClassData(data);
      setClassDate(data.classDate);
      data.studentList.map((item, index) => {
        console.log(item.classRecordDBID)
        if (!data.studentList[index]["updateFlag"]) {
          data.studentList[index]["updateFlag"] = false; //是否更新過
        }
        if (!data.studentList[index]["newRecord"]) {
          data.studentList[index]["newRecord"] = false; //是否為新增
        }
       
        //處理簽名資料
       let signPicResult=signPicAndProveData.signPicArray.find(signItem=>{
         return signItem.classRecordDBID==item.classRecordDBID
       })
       console.log("signPicResult",signPicResult,item.classRecordDBID)
       data.studentList[index]["signPic"]=signPicResult.signPic

      //處理請假資料
       let proveResult=signPicAndProveData.proveArray.find(proveItem=>{
        return proveItem.classRecordDBID==item.classRecordDBID
      })
      console.log("proveResult",proveResult)
      data.studentList[index]["prove"]=proveResult.prove

      });
      console.log('studentList',data.studentList)
      setStudentList(getStudentState(data.studentList, data.classDate,setViewUpdateState,setStudentList));
    };
    goGetInit(classDBID);
  }, []);

  const SaveStudentList = () => {
    function handelSave() {
      const operator = sessionStorage.getItem("staffDBID"); //未來請填入操作者 userID
      studentList.map((item, index) => {
        if (item.updateFlag) {
          console.log(item.name);
          const cmdUrl = `${url}/appGet/makeClassRecord?token=${token}&name=${item.name}&studentDBID=${item.studentDBID}&classDBID=${classDBID}&studentType=${item.studentType}&trialPayState=${item.trialPayState}&studentState=${item.studentState}&operator=${operator}&newRecord=${item.newRecord}&classRecordDBID=${item.classRecordDBID}`;

          fetch(cmdUrl)
            .then((res) => res.json())
            .catch((err) => {
              console.error("error:", err);
            })
            .then((res) => {
              console.log("success:", res);
            });
        }
      });

      window.location.href = `/SingleClassView/${classDBID}`;
    }
    let result = null;
    if (viewUpdateState) {
      result = (
        <>
          <button
            className="btn btn-info  mr-1 border-1 border-white"
            onClick={() => {
              let today = getDay(0, "-");
              window.location.href = `/SingleClassView/${classDBID}`;
            }}
          >
            <img src={cancelIcon} style={imgStyle} /> 取消
          </button>
          <button
            type="button"
            className="btn btn-danger border-1 border-white"
            onClick={handelSave}
          >
            <img src={saveIcon} style={imgStyle} alt="Home" /> 完成
          </button>
        </>
      );
    }
    return result;
  };




  

  
  

  return (
    <>
      <div className="card p-2 mt-2 border-1  vw-75">
        <div
          className="card  border-1 alert-info vw-75 text-center"
          id="getStudentList"
          available="false"
        >
         
  
        </div>
        <table className="table table-striped text-center vw-75">
          <thead>
            <tr>
              <th clasName="px-0" scope="row ">#</th>
              <th clasName="px-0" scope="col">姓名</th>
              <th clasName="px-0" scope="col">暱稱</th>
              <th clasName="px-0" scope="col">身分</th>
              <th clasName="px-0" scope="col">回饋</th>
            </tr>
          </thead>
          <tbody>
            {studentList.map((item, index) => {
                 let today = getDay(0, "-");
                 //let classDate = classData.classDate
                 console.log("classDate", classDate);
                 let dayDiff = dateDiff(classDate, today); // 日期差
               
              let toGoToStudentView = "/singleStudentViewForActionHistory/" + item["studentID"];
              let singleStudent = (
                <tr className="text-muted" key={index + 1}>
                  <td clasName="px-0" >{index + 1}</td>
                  <td clasName="px-0" 
                    scope="col"
                    onClick={() => {
                      window.location.href = `/singleStudentViewForActionHistory/${item["studentDBID"]}`;
                    }}
                  >
                    <span className="btn alert-info">{item["name"]} </span>
                  </td>
                  <td clasName="px-0" scope="col">{item["nickName"]}</td>
                  <td clasName="px-0" scope="col">{item["studentType"]}</td>

                  <td clasName="px-0" scope="col" onClick={() => {
                      window.location.href = `/singleClassFeedBack/${item["classRecordDBID"]}`;
                    }}>  <span className="btn alert-warning">檢視/編輯 </span></td>
                 
                </tr>
              );

              console.log(item)
              if (item.studentState=="完課"){
                return singleStudent;
              }
              
            })}
          </tbody>
        </table>
        {/* <div
          className="card  border-1 alert-info vw-75 text-center"
          id="getStudentList"
          available="false"
        >
          <div className=" p-1" align="right">
            <SaveStudentList />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ClassStudentListViewComponent;
