import React from "react";
import ReactDOM from "react-dom";
import { combineReducers, createStore } from "redux";
import { connect, Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import "./index.css";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
//redux



ReactDOM.render(

    <BrowserRouter>
      <Switch>
        <App />
      </Switch>
    </BrowserRouter>
,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
