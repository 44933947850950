import React, { useState, useEffect, useCallback, updateState } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from "../function/getUrl.js"
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay.js"

const token = sessionStorage.getItem("token")
//單堂簽到表
const GetDailySignSingleClassListTable = (props) => {
  checkLogin()
  let param = useParams();
  let classDBID = param.classDBID;

  let todayDate = getDay(0, "-")
  let staff = []

  const url = getUrl()
  const [studentList, setStudentList] = useState([])
  const [classData, setClassData] = useState({})


  useEffect(async () => {
    console.log("useEffect")
    //取得簽名與請假証明
    const getClassRecordSignPicAndProveData = async (classDBID) => {
      return fetch(
        `${url}/appGet/getClassRecordSignPic?classDBID=${classDBID}&token=${token}`
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return json;
        });
    };

    const fetCoachData = async() => {
      return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
        return res.json()
      }).then(json => {
        return json
      })
    }

    const fetchClassData = async (classDBID) => {
      return fetch(`${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          if (json.classDate >todayDate){
            alert('開課日期未到 不可簽到')
            window.location.href=`/SingleClassViewAfter/${classDBID}`
          }

          return {
            classData: json,
            studentList: json.studentList,
          }

        })
    }

    const fetchData = async () => {
      console.log("signPicAndProveData")
      let signPicAndProveData = await getClassRecordSignPicAndProveData(classDBID);
      console.log("end signPicAndProveData")
      const data = await fetchClassData(classDBID)
      data.studentList.map((item, index) => {
        
        
        //處理簽名資料
       let signPicResult=signPicAndProveData.signPicArray.find(signItem=>{
         return signItem.classRecordDBID==item.classRecordDBID
       })

       console.log("signPicResult",signPicResult,item.classRecordDBID)
       data.studentList[index]["signPic"]=signPicResult.signPic
       data.studentList[index]["signPicDBID"]=signPicResult.signPicDBID
      

      });


      setStudentList(data.studentList)
      setClassData(data.classData)
      // console.log("classData", classData)
      // console.log("studentList", studentList)
    };

    console.log("fetchData")
    fetchData()



  }, [])

  return (
    <>
      <div className="container  text-center" >
        <div className="card   alert-info border-1  ">
          <h5 className=" mt-1  mb-1 p-1">{classData.classDate} - {classData.classTime} 簽到表</h5>

        </div>

        <div className="card  mt-1 border-0   ">
          <div className="container vw-75">
            <table className="table table-striped">
              <thead>

                <tr className="text-center">
                  <th scope="row">#</th>
                  {/* <th scope="col">編號</th> */}
                  <th scope="col">姓名</th>
                  <th scope="col">暱稱</th>
                  <th scope="col">身分</th>
                  {/* <th scope="col">家長</th> */}
                  <th scope="col">簽到狀況</th>
                </tr>
              </thead>
              <tbody className="text-center">
               

                {
                  studentList.map((item, index) => {
console.log(item.studentState)
                   if (item.studentState=="劃位" ||item.studentState=="完課"){
                    // let imgSrc=null
                    // if (item.signPic!="none"){
                    //   imgSrc=item.signPic
                    // }
                    return (
                      <tr className="text-center" onClick={()=>{
                        window.location.href=`/canvasPad/${item.classRecordDBID}`
                      }}>
                        <td scope="row">{index + 1}</td>
                        {/* <td scope="col">{item.studentDBID}</td> */}
                        <td scope="col">{item.name}</td>
                        <td scope="col">{item.nickName}</td>
                        <td scope="col">{item.studentType}</td>
                        {/* <td scope="col">{item.parent}</td> */}
                        <td scope="col"><img src={item.signPic} width="100px"/></td>
                      </tr>
                    )
                   }else{
                     return null
                   }
                    
                  })
                }

              </tbody>
            </table>
            {/* <div className="btn btn-info" style={{ width: "80%" }} onClick={() => {
              window.location.href = "/GetDailySignTable"
            }}>回上頁</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default GetDailySignSingleClassListTable;
