import React, { useState, useEffect, useCallback, updateState } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from "../function/getUrl.js"
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay.js"

const token=sessionStorage.getItem("token")
//一日課表
const GetDailySignTable = (props) => {
  checkLogin()
  let param = useParams();
  //let todayDate = param.getDate;
  let todayDate = getDay(0,"-")
  let staff=[]

  const url = getUrl()
  if (todayDate == "") {
    todayDate =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate();
  }
  const fetCoachData = () => {
    return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
      return res.json()
    }).then(json => {
      staff=json
      return json
     
    })
  }
  
  fetCoachData()


  const [pickDate, setPickDate] = useState(todayDate);

  const [classList, setClassList] = useState(null);

  function getClassList(getDate) {
    return new Promise((resolve, reject) => {


      fetch(`${url}/appGet/getDailyClassTableAmount?date=${getDate}&token=${token}`, { mode: "cors" })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          json.sort((a, b) => {
            var nameA = a.classTime;
            var nameB = b.classTime;
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }

            return 0;
          });

          resolve(json);
        });
    });
  }

  function classPart(getDate) {

    

    getClassList(getDate).then((json) => {
      console.log(json)
      let classArray = [];
      for (let x in json) {
        let CardCss = "card text-white mt-1 mb-1   ";
        let CardBodyCss = "card P-0 m-1 ";
        switch (json[x]["age"]) {
          case "3-6M":
            CardCss += "bg-primary";
            CardBodyCss += "alert-primary";
            break;
          case "6-12M":
            CardCss += "bg-secondary";
            CardBodyCss += "alert-secondary";
            break;
          case "1-2Y":
            CardCss += "bg-danger";
            CardBodyCss += "alert-danger";
            break;
          case "2-3Y":
            CardCss += "bg-info";
            CardBodyCss += "alert-info";
            break;
          case "3-7Y":
            CardCss += "bg-warning text-dark";
            CardBodyCss += "alert-warning";
            break;
          case "7-12Y":
            CardCss += "bg-success";
            CardBodyCss += "alert-success";
            break;
          default:
            CardCss += "bg-light text-dark";
            CardBodyCss += "alert-light";
            break;
        }

        let studentArray = json[x]["studentList"];
        studentArray.sort((a, b) => {
          var nameA = a.state;
          var nameB = b.state;
          if (nameA > nameB) {
            return 1;
          }
          if (nameA < nameB) {
            return -1;
          }

          return 0;
        });
        let studentShow = "";
        let studentDone = []; //完課
        let studentBooking = []; //劃位
        let studentBackup = []; //候補
        let studentCancel = []; //請假

        let studentNoshow = []; //缺課


        for (let q in studentArray) {
          switch (studentArray[q].studentState) {
            case "劃位":
              studentBooking.push(
                <div className="m-0">
                  <small> {studentArray[q].name} ({studentArray[q].classBalance})</small>
                </div>
              );
              break;
            case "完課":
              studentDone.push(
                <div className="m-0">
                  <small> {studentArray[q].name} ({studentArray[q].classBalance})</small>
                </div>
              );
              break;
            case "取消":
              studentCancel.push(
                <div className="m-0">
                  <small> {studentArray[q].name} ({studentArray[q].classBalance})</small>
                </div>
              );
              break;
            case "候補":
              studentBackup.push(
                <div className="m-0">
                  <small> {studentArray[q].name} ({studentArray[q].classBalance})</small>
                </div>
              );
              break;
            case "缺課":
              studentNoshow.push(
                <div className="m-0">
                  <small> {studentArray[q].name} ({studentArray[q].classBalance})</small>
                </div>
              );
              break;
            default:
              break;
              
          }
          studentShow = (
            <>
              <div className="container vw-75">
                <table className=" table table-sm">
                  <thead>
                    <th >劃位</th>
                    <th>完課</th>
                    <th>候補</th>
                    <th>缺課</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td >{studentBooking}</td>
                      <td>{studentDone}</td>
                      <td>{studentBackup}</td>
                      <td>{studentNoshow}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          );
        }
        console.log("staff",staff)
        staff.map((item,index)=>{
          console.log("item.staffDBID",item.staffDBID,"json[x][coachID]",json[x]["coachID"])
          if (item.staffDBID==json[x]["coachID"]){
            json[x]["coach"]=item.name
          }
          if (item.staffDBID==json[x]["assistantID"]){
            json[x]["assistant"]=item.name
          }
        })

        let toClass = `/GetDailySignSingleClassListTable/` + json[x]["classDBID"]
        let singleClass = (

          <Link style={{ textDecoration: "none" }} to={toClass} >
            <div className={CardCss}>
              <h5 className="card-header">
                {json[x]["classTime"]} {json[x]["age"]}{" "}
              </h5>
              <div className="card-text">
                限:{json[x]["limit"]} 劃:{json[x]["booking"]} 完:
              {json[x]["done"]} 補:{json[x]["backup"]} 缺:{json[x]["noshow"]}
              </div>
              <div className="card-text m-0 ">
                教練:{json[x]["coach"]}/助教：{json[x]["assistant"]}
              </div>
              
              <div className={CardBodyCss} key={json[x]["classID"]}>
                <div>{studentShow}</div>
              </div>
            </div>
          </Link>
        );

        classArray.push(singleClass);
      }

      setClassList(classArray);
    });
  }

  function handleChangeDate(e) {
    let pickDateE = e.target.value;

    setPickDate(pickDateE);
  }

  function handleClick() {
    let date = document.getElementById("startDateValue").value;

    classPart(date);
  }

  useEffect(() => {
   
    classPart(todayDate);
  }, []);

  return (
    <>
      <div className="container vw-75 text-center" style={{ maxWidth: "500px" }}>
        <div className="card   alert-info border-1  ">
          <h5 className=" mt-1  mb-1 p-1">{pickDate} 簽到表</h5>
          {/* <div className=" p-2 ">
            <div className="input-group mb-3 ">
              <div className="input-group-prepend">
                <span className="input-group-text " id="basic-addon1">
                  日期
                </span>
              </div>
              <div></div>
              <input
                type="date"
                className="form-control"
                id="startDateValue"
                value={pickDate}
                onChange={handleChangeDate}
                aria-label="Username"
                aria-describedby="basic-addon1"
              />

              <button
                className=" ml-1 btn btn-danger"
                style={{ width: "30%" }}
                onClick={handleClick}
              >
                送出
              </button>
            </div>
        
  
            <div>

            </div>
          </div> */}
        </div>

        <div className="card  mt-1 border-0   ">{classList}</div>
      </div>
    </>
  );
};

export default GetDailySignTable;
