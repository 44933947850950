
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//個案核備核議
const ReportEdit = () => {

  checkLogin()

  let param = useParams(); //功能項目
  let reportDBID = param.reportDBID;
  const url = getUrl()
  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const [name,setName]=useState(sessionStorage.getItem('name'))
  const level=sessionStorage.getItem('level')



  //捕捉 select中被選的option
  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  const getReport=async ()=>{

    let result=fetch(`${url}/appGet/getReport?reportDBID=${reportDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let thisReport=json.find(item=>{
          return item.reportDBID==reportDBID
        })
        console.log(thisReport)
        if (thisReport){
          document.getElementById('createDate').value=thisReport.createDate
          document.getElementById('operator').innerHTML=thisReport.name
          document.getElementById('reportType').innerHTML=thisReport.reportType
          document.getElementById('context').innerHTML=thisReport.context
          document.getElementById('comments').innerHTML=thisReport.comments
          

          let option = document.getElementById("result").options;
          for (let y in option){
            if (option[y].value == thisReport.result) {
              option[y].selected = true;
            }
      
          }
          
     

        }
  
        return result
      });
      
  }
 
useEffect(()=>{
  getReport()
  if (level!=5){
    alert('限權不足')
    window.location.href = `/GetReportList`
  }

},[])




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">個案核備 核議者: {name} </h5>
        </div>
        <div className="mt-2">
       

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-dark">個案融通內容</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請人
                      </span>
                     
                </div>
                <div 
                 
                 id="operator"
                 className="form-control"
                 aria-describedby="validationTooltipUsernamePrepend"
                
               />
              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請日期
                      </span>
                     
                </div>
                <input 
                 
                 id="createDate"
                 className="form-control"
                 aria-describedby="validationTooltipUsernamePrepend"
                
               />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請類別
                      </span>
                </div>
                <div id="reportType" className="form-control"  >
                
                </div>
              </div>
            </div>

           

            <div className="m-1 "> <small className="ml-2 text-muted" >個案內容與需求</small>
              <div className="input-group">
               
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    變更內容說明
                      </span>
                </div>
                <textarea class="form-control" id="context" rows="10" aria-describedby="validationTooltipUsernamePrepend" disabled='true'></textarea>
               

              </div>
            </div>




          </div>


          <div className="m-1 "> <small className="ml-2 text-muted" ></small>
              <div className="input-group">
               
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                   決議回覆
                      </span>
                </div>
                <textarea class="form-control" id="comments" rows="5"  aria-describedby="validationTooltipUsernamePrepend"></textarea>
               

              </div>
            </div>

          <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    決議
                      </span>
                </div>
                <select id="result" className="custom-select" required >
                  <option >未決</option>
                  <option >同意</option>
                  <option >退回</option>
                  
                </select>
              </div>
            </div>
         

          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={()=>{
            
                // let result=document.getElementById('result').value
                let comments=document.getElementById('comments').value
                let result = getSelect(document.getElementById("result").options);
                if (comments.length<2){
                  alert('請填寫決議回覆！')
                  return
                }

                if(!window.confirm('內容確定無誤，要核議了嗎？')){
                  return 
                }

                var formData = new FormData();
                formData.append("operator", operator);
                formData.append("result", result);
                formData.append("comments", comments);
                formData.append('token',token)
                formData.append('reportDBID',reportDBID)
               

                fetch(`${url}/appPost/editReport`, {
                  method: "POST",
                  body: formData,
                  mode: "cors",
                })
                  .then((res) => {
                    return res.json()
          
                  }).then(() => {
                    alert('核議完成')
                    window.location.href = `/GetReportList`
                  })


              }}
              className="btn btn-danger "
            >
              送出核議
                </button>
            <Link to={`/home`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default ReportEdit;