
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"
//課程畫面元件
const StaffAdd = () => {

  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  checkLevel()
  let param = useParams(); //功能項目
  let staffDBID = param.staffDBID;
  console.log("staffDBID",staffDBID)
  const [staff, setStaff] = useState([])
  const url = getUrl()
  const today = getDay(0, "-")
  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const level=sessionStorage.getItem("level")

  // if (parseInt(level,"10")==1){
  //   window.location.href="/home"
  // }
  // console.log("level",level)
 


  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function handelSummit(e) {
   
    var formData = new FormData();
    formData.append("staffDBID", staffDBID);
    formData.append("name", document.getElementById("name").value);
    formData.append("nickName", document.getElementById("nickName").value);
    formData.append("phone", document.getElementById("phone").value);
    formData.append("password", document.getElementById("password").value);
    formData.append("title", document.getElementById("title").value);
    formData.append("alive", document.getElementById("alive").value);
    formData.append("coach", document.getElementById("coach").value);
    formData.append("level", document.getElementById("level").value);
    formData.append("operator", operator);
    formData.append("token", token)
    fetch(`${url}/appPost/addNewStaff`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()

      }).then(() => {
        window.location.href = `/staffListView`
      })

    // return window.location.href = `/SingleStudentView/${studentDBID}`
  }




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1">新增員工資料</h5>
        </div>
        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">基本資料區</div>
            <div className="card-body ">

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      姓名
                      </span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      暱稱
                      </span>
                  </div>
                  <input
                    type="text"
                    id="nickName"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      手機
                      </span>
                  </div>
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

              <div className="m-1" id="passwordArea">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      密碼
                      </span>
                  </div>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      職稱
                      </span>
                  </div>
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                  />
                </div>
              </div>

             

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否在職
                      </span>
                  </div>
                  <select id="alive" className="custom-select" required>
                    <option value="true" selected>Yes</option>
                    <option value="false" >No</option>
                  </select>
                </div>
              </div>

              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否授課
                      </span>
                  </div>
                  <select id="coach" className="custom-select" required>
                    <option value="true">Yes</option>
                    <option value="false" selected>No</option>
                  </select>
                </div>
              </div>

              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      權限
                      </span>
                  </div>
                  <select id="level" className="custom-select" required>
                    <option value="1" selected>一般職員/教練</option>
                    <option value="2"  >櫃台行政作業</option>
                  </select>
                </div>
              </div>

            </div>
          </div>



          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={handelSummit}
              className="btn btn-danger "
            >
              更新
                </button>
            <Link to={`/staffListView`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default StaffAdd;