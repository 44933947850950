import React, { useState, useEffect } from 'react';
import searchIcon from '../icon/search.svg'
import getUrl from '../function/getUrl'
import checkLogin from "../function/checkLogin.js"
import reloadIcon from '../icon/reload.svg'
import pencilIcon from "../icon/pencil.svg";
import checkLevel from "../function/checkLevel.js"
import getDay from "../function/getDay"
const url = getUrl()


const token = sessionStorage.getItem("token")
const operator = sessionStorage.getItem("staffDBID")
const level = parseInt(sessionStorage.getItem("level"), "10")
var imgStyle = {
    width: '20px',
    height: '20px',
};

const ProductListView = (props) => {
    checkLogin()
    checkLevel()
    const [orderList, setOrderList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [thisMonthSaleTotal,setThisMonthSaleTotal]=useState(0)
    const thisMonth=()=>{
        
  
            let tempMonth=parseInt(new Date().getMonth(),"10")+1 
            if (tempMonth < 10){
                return new Date().getFullYear()+"-0"+tempMonth+"-01"
            }
            
      
        return new Date().getFullYear()+"-"+tempMonth+"-01"
    }

    function handleSearch(e) {

        let name = document.getElementById("name").value
        const getNameOrder = (name) => {
            let getUrl=null
            if (name==""){
                getUrl=`${url}/appGet/getProductOrderData?token=${token}`
            }else{
                getUrl=`${url}/appGet/getProductOrderData?token=${token}&name=${name}`
            }
            return fetch(getUrl).then((res) => {
                return res.json()
            }).then((json) => {
                console.log(json)
                json.sort((a, b) => {

                    var nameA = parseInt(a.orderDBID, "10"); // ignore upper and lowercase
                    var nameB = parseInt(b.orderDBID, "10"); // ignore upper and lowercase
                    if (nameA > nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                })

                return json

            })
        }

        const getSearchResult= async (name)=>{
            let data = await getNameOrder(name)
            setOrderList(data)
        }

        getSearchResult(name)
    }


    function getOrderData() {
        return fetch(`${url}/appGet/getProductOrderData?token=${token}`).then((res) => {
            return res.json()
        }).then((json) => {
            console.log(json)
            json.sort((a, b) => {

                var nameA = parseInt(a.orderDBID, "10"); // ignore upper and lowercase
                var nameB = parseInt(b.orderDBID, "10"); // ignore upper and lowercase
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            })

            return json
        }).then((data=>{
            setOrderList(data)
            setThisMonthSaleTotal(0)
            let tempTotal=0
            data.map(item=>{
                if (item.orderDate > thisMonth()){
                    tempTotal+=parseInt(item.total,"10")
                }
            })
            setThisMonthSaleTotal(tempTotal)
        })).catch((err) => {
            console.log(err)
        })
    }




    useEffect(() => {

        getOrderData()

    }, [])
    return (
        <>
            <div className="card alert-info p-2">
                <div className="container text-center" style={{ width: "500px" }}>
                    <h5 className="m-1 text-center">商品訂單資料維護</h5>
                        <h6>本月商品銷售總額 ${thisMonthSaleTotal }</h6>
                    <div className="card-body">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1"> <img src={searchIcon} style={imgStyle} alt="search" /> Search</span>
                            </div>

                            <input type="text" id="name" className="form-control" placeholder="商品名稱" aria-label="Username" aria-describedby="basic-addon1" onChange={handleSearch}/>

                        </div>
                        {/* <div className="w-100 container ">
                            <button className="btn btn-info" onClick={handleSearch} style={{ width: "100%" }} ><img src={reloadIcon} style={imgStyle} /> 送出</button>
                        </div>
 */}

                    </div>
                </div>

            </div>
            <div className="mt-5"  >
                <table className="table" >

                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}>編號 </th>
                            <th style={{ width: "10%" }}>發票號碼</th>
                            <th style={{ width: "10%" }}>訂單日期</th>
                            <th style={{ width: "10%" }}>商品名稱</th>
                            <th style={{ width: "5%" }}>金額</th>
                            <th style={{ width: "5%" }}>數量</th>
                            <th style={{ width: "5%" }}>總金額</th>
                            <th style={{ width: "10%" }}>經手人</th>


                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderList.map((item, index) => {


                                return (
                                    <tr className="text-muted" 
                                    >
                                        <td>{item['orderDBID']} </td>
                                        <td>{item['invoiceID']} </td>
                                        <td>{item['orderDate']}</td>
                                        <td>{item['productName']}</td>
                                        <td>{item['price']}</td>
                                        <td>{item['amount']}</td>
                                        <td>{item['total']}</td>
                                        <td>{item['staffName']}</td>



                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ProductListView;