import React ,{useState,useEffect} from 'react';
import birthdayToAge from '../function/birthdayToAge.js'

import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import Fuse from 'fuse.js'
import getUrl from '../function/getUrl'
import checkLogin from "../function/checkLogin.js"

const url=getUrl()
const StudentListView=(props)=>{
    checkLogin()
    let studentData=JSON.parse(sessionStorage.getItem('student'));

    
    var imgStyle = {
        width:'20px',
        height:'20px',
      };
      studentData.sort((a,b)=>{
  
        var nameA = a.studentID; // ignore upper and lowercase
        var nameB = b.studentID; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      
      // names must be equal
      return 0;
      })
    const [studentList,setStudentList]=useState(null)
    let studentArray=[]
    for (let x in studentData){
        let toEdit=""
        let age=birthdayToAge(studentData[x]['birthday'],"/")
  
        let singleStudent=
       
        <>
       
                <tr>
                    <td>{studentData[x]['studentID']} </td>
                    <td>{studentData[x]['name']}</td>
                    <td>{studentData[x]['nickName']}</td>
                    <td>{studentData[x]['studentType']}</td>
                    <td>{studentData[x]['birthday']}</td>
                    <td>{age[0]}</td> 
                    <td>{age[1]}</td>
                    <td>{studentData[x]['parent']}</td>
                   
                </tr>
        </>
        
        studentArray.push(singleStudent)
      }
     

    function handleChange(e){
       //如果search 變回空白 則回到原始
        if (e.target.value !=""){
            let options={
                keys:['name','parent']
            }
    
            let fuse= new Fuse(studentData, options)
    
            let searchResult = fuse.search(e.target.value)
            
            //console.log("s.l:",searchResult.length )
            if (searchResult.length >0 ){
            let clientList=[]
             for (let x=0;x<searchResult.length;x++){
              
               let age=birthdayToAge(searchResult[x]['birthday'],"/")
                let singleStudent=
           
                    <>
                    <tr>
                        <td>{searchResult[x]['studentID']} </td>
                        <td>{searchResult[x]['name']}</td>
                        <td>{searchResult[x]['nickName']}</td>
                        <td>{searchResult[x]['studentType']}</td>
                        <td>{searchResult[x]['birthday']}</td>
                        <td>{age[0]}</td> 
                        <td>{age[1]}</td>
                        <td>{searchResult[x]['parent']}</td>
                       
                    </tr>
                    </>
           
               
              
               clientList.push(singleStudent)
             }
           
             setStudentList(clientList)
             //setAmout(searchResult.length)
            }else{
                setStudentList(null)
              //setAmout(Object.keys(clientData).length)
            }
             
           
        }else{
            setStudentList(studentArray)
        }
    };
    
    function handleReload(){
        window.location.reload()
    }
    

useEffect(()=>{
    setStudentList(studentArray)
},[])
    return (
        <>
        <div className="card alert-info p-2">
            <h5 className="m-1">學員資料維護</h5>
           
            <div className="card-body">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"> <img src={searchIcon} style={imgStyle} alt="search"/> Search</span>
                    </div>
                        <input type="text" className="form-control" placeholder="學員姓名、家長姓名" aria-label="Username" aria-describedby="basic-addon1" onChange={handleChange}/>
                        <span>  &nbsp; &nbsp; </span><button className="btn btn-info w-10" onClick={handleReload} ><img src={reloadIcon} style={imgStyle} /> 重載最新資料</button>
                </div>
                <div>
                    
                </div>
                
            </div>
        </div>
            <div className="mt-5"  >
                <table className="table" >
                    
                    <thead>
                        <tr>
                          <th style={{width:"10%"}}>編號 </th>
                          <th style={{width:"10%"}}>姓名</th>
                          <th style={{width:"10%"}}>暱稱</th>
                          <th style={{width:"10%"}}>身分</th>
                          <th style={{width:"10%"}}>生日</th>
                          <th style={{width:"10%"}}>年紀</th>
                          <th style={{width:"10%"}}>適合分班</th>
                          <th style={{width:"10%"}}>家長</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                    {studentList}
                    </tbody>
                  </table>
        </div>
        </>
    )
}

export default StudentListView;