import React ,{useState,useEffect,useCallback,updateState} from 'react';
import { Link ,useParams} from 'react-router-dom';
import GetDailyTableComponent from '../pages/GetDailyTableComponent.js'
import getDay from '../function/getDay.js'
import checkLogin from "../function/checkLogin.js"
//周課表
const GetWeekTable=(props)=>{
    checkLogin()
   const [classTableData,setClassTableData]=useState(<p>載入中...</p>)
    let param=useParams()
    //let pickDate=param.getDate
   
    const [pickDate,setPickDate]=useState(param.getDate)
    function getDayAdd(date,num){
        var d1 = new Date(date); 
        var d2 = new Date(d1); 
        d2.setDate(d2.getDate()+num); 

        let result=d2.getFullYear()+"-"+(d2.getMonth()+1)+"-"+d2.getDate()

        return result
    }
    
    function handleChangeDate(e){

        let pickDateE=e.target.value
    

           setPickDate(pickDateE)

           
      
    }
    
    function handleClick(){
        


       let date=document.getElementById('startDateValue').value
 
       window.location.href=`/GetWeekTable/${date}`
      
    }

    
   
    return (
        <>
       
         <div className="container  text-center" style={{minWidth:"1200px"}}>
         <div className="card alert-info border-1 p-2 " > 
          
         <div className="container vw-75" style={{maxWidth:"600px"}}>
           <h5 className=" mb-1 mt-1">{param.getDate} 周課表</h5>
            <div className="card-body">
            <div className="input-group  p-1" style={{width: "18rem;"}}>
                                <div className="input-group-prepend">
                                  <span className="input-group-text " id="basic-addon1">日期</span>
                                </div>
                                <div>
                                  
                                </div>
                                <input type="date" className="form-control" id="startDateValue"  value={pickDate}  onChange={handleChangeDate} aria-label="Username" aria-describedby="basic-addon1" />

                                <button className=" ml-1 btn btn-danger"  style={{width:"30%"}} onClick={handleClick}>送出</button>
                    </div>
            </div>
        </div>
        </div>
        </div>          
        
            
           <div className="p-1 border-0 rounded " style={{minWidth:"1200px"}}>
           <table className="table p-0" >
               <tr className="p-0">
                   <td ><GetDailyTableComponent getDate={getDayAdd(pickDate,0)}/></td>
                   <td ><GetDailyTableComponent getDate={getDayAdd(pickDate,1)}/></td>
                   <td><GetDailyTableComponent getDate={getDayAdd(pickDate,2)}/></td>
                   <td><GetDailyTableComponent getDate={getDayAdd(pickDate,3)}/></td>
                   <td><GetDailyTableComponent getDate={getDayAdd(pickDate,4)}/></td>
                   <td><GetDailyTableComponent getDate={getDayAdd(pickDate,5)}/></td>
                   <td><GetDailyTableComponent getDate={getDayAdd(pickDate,6)}/></td>

               </tr>
                        
         </table>
                        </div>


        </>
    )
    
}

export default GetWeekTable;