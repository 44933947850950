
function checkLogin(){
    const timecode=sessionStorage.getItem("timeCode")
    const now= Date.now()
    //console.log("timecode",timecode)
    if (now > timecode  || timecode==null){
        //時間過期
        //sessionStorage.clear() //清空資料
        window.location.href="/login"
    }
    return true


}

export default checkLogin;