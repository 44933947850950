import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import pencilIcon from "../icon/pencil.svg";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import FunBar from "./funBar.js";
import getUrl from '../function/getUrl'
import StudentCard from '../components/studentCard.js'

//快速學生資訊-自由練習查詢
const url=getUrl()
const token=sessionStorage.getItem("token")
const SingleStudentView = (props) => {
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  let studentEdit = "/studentEdit/" + studentDBID;
  let goLink = "";
  let total = 0; //
  let classCount = 0; //總購課數
  let cancel = 0; //取缺數
  let noshow=0;//缺課
  let done = 0; //完課數
  let reservation = 0;
  const [student, setStudent] = useState(null);
  const [orderList, setOrderList] = useState(null);
  const [classList, setClassList] = useState(null);
  const [studentClassState, setStudentClassState] = useState(null);
  const [studentIDState, setStudentID] = useState(null);
  const [studentNameState, setStudentName] = useState(null);
  const [freeAmount,setFreeAmount]=useState(0)
  const [freeDoneAmount,setFreeDoneAmount]=useState(0)
  var imgStyle = {
    width: "20px",
    height: "20px",
  };
  var fontStyle = {
    fontSize: "0.5em",
  };

function getFreeOrder(){
  fetch(`${url}/appGet/singleStudentFreeOrder?studentDBID=${studentDBID}&token=${token}`).then(res=>{
    return res.json()
  }).then(json=>{
    console.log(json)

    let orderArray = [];
    let freeAmountTemp=0

        for (let x in json) {
          classCount += parseInt(json[x]["classAmount"], 10);
          let singleOrder = (
            <>
            <tr className="text-muted text-center" > 
              <th scope="row">{parseInt(x, 10) + 1}</th>
              <td>{json[x]["orderDBID"]}</td>
              <td>{json[x]["orderDate"].substring(0, 10)}</td>
              <td>{json[x]["eventName"]}</td>
              <td>{json[x]["eventPrice"]}</td>
              <td>{json[x]["amount"]}</td>
              <td>{json[x]["total"]}</td>
              <td  className="text-muted text-center">{json[x]["memo"]}</td>
            </tr>
           
            </>
          )
            freeAmountTemp+=parseInt(json[x]["amount"],"10")
          orderArray.push(singleOrder);
        }
        setFreeAmount(freeAmountTemp)
        setOrderList(orderArray);
    return 
  })
}

  function getData() {
   

    fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {

        if (json["studentType"]=="體驗"){
          window.location.href=`/SingleStudentTrialView/${studentDBID}`
        }
        console.log(json);

        console.log(json["birthday"]);
        let age = birthdayToAge(json["birthday"], "/");
        setStudentID(json["studentID"]);
        setStudentName(json["name"]);
        let singleStudent = (
          <>
           <div className="card mt-1  alert-dark" >
              <div className="row">
                <div className="col-sm m-0 ">
                  <div className="card-body rounded border-0 ">
                    <h6 className="card-title">基本資料</h6>
    
                    <ul className="list-group list-group-flush text-left ">
                    
                      <li className="list-group-item">姓名：{json['name']}</li>
                      <li className="list-group-item">暱稱：{json['nickName']}</li>
                      <li className="list-group-item">編號：{json['studentDBID']}</li>
                      <li className="list-group-item">性別：{json['gender']}</li>
                      <li className="list-group-item">身分：{json['studentType']}</li>
                      <li className="list-group-item">生日：{json['birthday']}</li>
                      <li className="list-group-item">年紀：{age[0]}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm m-0 ">
                  <div className="card-body rounded border-0 ">
                    <h6 className="card-title">家長資料</h6>
    
                    <ul className="list-group list-group-flush text-left ">
                      <li className="list-group-item">姓名：{json['parent']}</li>
                      <li className="list-group-item">稱謂：{json['title']}</li>
                      <li className="list-group-item">電話：
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                         09********
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json["phone"]}</div>
                      </div>
                      </li>
                     
                      <li className="list-group-item">APP：
                      <div className="btn-group ">
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                         ****
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json["password"]}</div>
                      </div>
                    </div>
                      </li>
                     
                      <li className="list-group-item">FB：{json['fb']}</li>
                      <li className="list-group-item">Line：{json['line']}</li>
                      <li className="list-group-item">Email：
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                       {json['email'].substring(0,3)}***{json['email'].substring(json['email'].length-10,json['email'].length)}
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['email']}</div>
                      </div>
                      </li>
                    </ul>
                  </div>
                </div>
    
                <div className="col-sm m-0 ">
                  <div className="card-body rounded border-0 ">
                    <h6 className="card-title">備註</h6>
    
                    <ul className="list-group list-group-flush text-left ">
                      <li className="list-group-item">介紹人：{json['introPeople']}</li>
                      <li className="list-group-item">來源：{json['comefrom']}</li>
                      <li className="list-group-item">地址：
                      
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {json['address'].substring(0,3)}***{json['address'].substring(json['address'].length-3,json['address'].length)}
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['address']}</div>
                      </div>
                      </li>
                      <li className="list-group-item">游泳經驗：{json['swimExp']}</li>
                      <li className="list-group-item">健康聲明：{json['healthMemo']}</li>
                      <li className="list-group-item">備註：{json['memo']}</li>
                      <li className="list-group-item">櫃台註記：
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle  p-1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                         Memo
                      </button>
                      <div className="dropdown-menu">
                        <div className="dropdown-item">{json['parentMemo']}</div>
                      </div>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

        setStudent(singleStudent);

       

        let classArray = [];
        json["classList"].sort((a, b) => {
          var nameA = a.classDate;
          var nameB = b.classDate;
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
        let freeDoneAmount=0
        for (let x in json["classList"]) {
          let getDate = json["classList"][x]["classDate"].substring(0, 10);
          var dateWeek = new Date(getDate).getDay();
          var day_list = ["日", "一", "二", "三", "四", "五", "六"];
          var week = day_list[dateWeek];

          let singleClass = "";
           
           
            if (json["classList"][x]["studentState"] =="自由練習完課" ){
              freeDoneAmount+=1
              singleClass = (
                <tr className="text-muted text-center"  onClick={
                  ()=>{
                   window.location.href=`/SingleClassView/${json["classList"][x]["classDBID"]}`
                  }
                 }>
                  <th scope="row">{freeDoneAmount}</th>
                  <td>
                    {json["classList"][x]["classDate"].substring(0, 10)} ({week})
                  </td>
                  <td>{json["classList"][x]["classTime"]}</td>
                  <td>{json["classList"][x]["age"]}</td>
                  <td>{json["classList"][x]["studentType"]}</td>
                  <td ><span className="text-info font-weight-bold">{json["classList"][x]["studentState"]}</span></td>
                </tr>
              );
            }
          
          

          classArray.push(singleClass);

          
        }
        setClassList(classArray);
        setFreeDoneAmount(freeDoneAmount)
        total = noshow + done;
        let studentAnalysis = (
          <>
            <table className="table table-striped text-center vw-75">
              <thead className="text-muted">
                <tr>
                  <th scope="col">已劃位</th>
                  <th scope="col">可劃位</th>
                  <th scope="col">購課數</th>
                  <th scope="col">餘課數</th>
                  <th scope="col">缺課數</th>
                  <th scope="col">完課數</th>
                
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{reservation}</th>
                  <td>{classCount - done - reservation}</td>
                  <td>{classCount}</td>
                  <td>{classCount - done}</td>
                  <td>{noshow}</td>
                  <td>{done}</td>
                  
                </tr>
              </tbody>
            </table>
          </>
        );
        setStudentClassState(studentAnalysis);
      });
  }

  useEffect(() => {
    getData();
    getFreeOrder();
  }, []);

  return (
    <>
      <div className="container  text-center">
        <div>
          <FunBar studentDBID={studentDBID} />
        </div>
        <div className="mt-1 alert alert-danger">自由練習查詢</div>
        <StudentCard studentDBID={studentDBID} />
      {/* <div className="vw-75">
      {student}
      </div> */}

        <div className="card alert-success p-2 mt-1">
          <h5 className="m-1">自由練習-訂單資料 總堂數 : {freeAmount}堂</h5>
        </div>

        <table className="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="row">#</th>
              <th scope="col">編號</th>
              <th scope="col">日期</th>
              <th scope="col">專案</th>
              <th scope="col">單價</th>
              <th scope="col">數量</th>
              <th scope="col">總價</th>
              <th scope="col">備註</th>
            </tr>
          </thead>
          <tbody className="text-center vw-75">{orderList}</tbody>
        </table>

        <div className="card alert-danger p-2 mt-1">
          <h5 className="m-1">自由習練上課記錄 已完課堂數 : {freeDoneAmount}堂</h5>
        </div>
        {/* <div className="mb-2 vw-75">{studentClassState}</div> */}

        <table className="table table-striped">
          <thead>
            <tr className="text-center mt-3">
              <th scope="row">#</th>
              <th scope="col">日期</th>
              <th scope="col">時間</th>
              <th scope="col">班別</th>
              <th scope="col">身分</th>
              <th scope="col">狀態</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {classList}
          
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SingleStudentView;
