import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'
import getUrl from '../function/getUrl'
import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"


const url=getUrl()
//快速查詢學生
const token=sessionStorage.getItem("token")
const GetStudentList=(props)=>{
    checkLogin()
    const [studentState,setStudentState]=useState('all')
    let param=useParams() //功能項目
    let togo=param.togo
    let title=""
    let goLink=""
    let getDataURL=""
    //設計功能切換
    //按排訂單。。。。
    switch (togo){
        case "trial":
            goLink="/SingleStudentTrialView/"
            title="體驗報名處理"
            getDataURL=`${url}/appGet/getStudentByTrialFlag`
            break;
        case "detail":
            goLink="/SingleStudentView/"
            title="學員維護"
            getDataURL=`${url}/appGet/getStudentByName`
            break ;
        default:
            goLink="/"
            break ;
    }
    //進度條
    let loadingState=<>
    <h5 className="text-info mt-5">資料載入中...</h5>
    <div className="progress w-50">
        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}></div>
        </div>
    </>
    const [studentList,setStudentList]=useState(loadingState)
   
    var imgStyle = {
        width:'20px',   
        height:'20px',
      };

    function hanedleState(e){
       
        handleSearch()
    }
    function handleSearch(e){
        setStudentList(loadingState)
        let name=document.getElementById('searchName').value
       
   
        console.log('url',url)
        let studentArray=[]
     
        fetch(`${getDataURL}?name=${name}&token=${token}`).then((res)=>{
            return res.json();
        }).then(json=>{

            json.sort((a, b) => {
                var nameA = a.classAmount;
                var nameB = b.classAmount;
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            });

            let studentState=document.getElementById('studentState').value
            for (let x in json){
                let age=birthdayToAge(json[x]['birthday'],"/")
                
                let singleStudent=
                        <tr onClick={()=>{ window.location.href=`${goLink}${json[x]['studentDBID']}`}} className="text-muted" style={{ textDecoration: 'none' }} key={x}>
                        <th scope="row">{parseInt(x, 10)+1}</th>
                        <td>{json[x]['studentDBID']}</td>
                        <td>{json[x]['name']}</td>
                        <td>{json[x]['nickName']}</td>
                        <td>{json[x]['studentType']}</td>
                        <td>{json[x]['birthday']}</td>
                        <td>{age[0]}</td>
                        <td>{json[x]['parent']}</td>
                    </tr>
                switch (studentState){
                    case 'trial':
                        if (json[x]['studentType']=="體驗"){
                            studentArray.push(singleStudent) 
                        }  
                        break;
                    case 'normal':
                        if (json[x]['studentType']=="正式"){
                            studentArray.push(singleStudent)
                        }
                    break;
                    default:
                        studentArray.push(singleStudent)
                }

               

           
    
            }
            
        }).then(()=>{
            setStudentList(studentArray)
        })
    }
    
useEffect(()=>{
    handleSearch()
},[])
    
    return (
        <>
        <div className="container text-center">
         <div className="card alert-info p-2 " >
             <div className="container vw-75" style={{maxWidth:"500px"}}>
            <h5 className="m-1">{title}</h5>
           
           <h6 className="m-1 text-muted">請先挑選學員</h6>
            <div className="card-body">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"> <img src={searchIcon} style={imgStyle} alt="search"/> Search</span>
                    </div>
                        <input type="text" className="form-control " id="searchName" placeholder="學員、家長姓名" aria-label="Username" aria-describedby="basic-addon1" />
                       <button className="btn btn-info w-20" onClick={handleSearch} ><img src={reloadIcon} style={imgStyle} /> 送出</button>
                        
                </div>
              
                <div className="input-group mb-3 ml-6  " >
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">Search結果篩選</span>
                    </div>
                            <select id="studentState" className="custom-select p-1  text-center" style={{width:"120px"}} onChange={hanedleState}>
                                <option value="all" selected>全部</option>   
                                <option value="trial"> 體驗</option>
                                <option value="normal"> 正式</option>
                                
                            </select>
                       
                </div>
                </div>
                <div>
                </div>
                
            </div>
           
        </div>

        <hr/>
 
                
                        <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                    <th scope="row">#</th>    
                    <th scope="col">編號</th>
                    <th scope="col">姓名</th>
                    <th scope="col">暱稱</th>
                    <th scope="col">身分</th>
                    <th scope="col">生日</th>
                    <th scope="col">年紀</th>
                    <th scope="col">家長</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                {studentList}
                    
                </tbody>
                </table>
                </div>
        </>
    )
    
}



export default GetStudentList;