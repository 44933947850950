
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//課程銷售
const ProductOrderAdd = () => {
  //銷售後 要同步調整 學員身分別
  // 體驗銷售 不得銷售給 正式學員
  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  let param = useParams(); //功能項目
  const url = getUrl()
  const today = getDay(0, "-")
  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const [salesEvent, setSalesEvent] = useState([])
  const [product,setProduct]=useState([])
  const orderAmountNumber=["1","2","3","4","5","6","7","8","9"]



  const fetchProduct=()=>{
    return fetch(`${url}/appGet/getProduct?token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
     
      json.map((item,index)=>{
        json[index].price=parseInt(json[index].price,"10")
      })

      json.sort((a,b)=>{
        var nameA = a.price; 
        var nameB = b.price; 
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
      return 0;
      })
     
      return json

    }).then((json)=>{
      setProduct(json)
      console.log(json)
      // 初始化 專案選項
      let eventOption = document.getElementById("eventSelected").options;
      document.getElementById('orderDate').value=today
      document.getElementById('eventSelected').value=json[0].name
      document.getElementById('price').value=parseInt(json[0].price,"10")
      document.getElementById('amount').value = 1
      let total=document.getElementById('amount').value*document.getElementById('price').value
      document.getElementById('total').value =total
      document.getElementById('taxPrice').innerHTML=`未稅 ${total*0.95} /稅金 ${total*0.05}`
      eventOption[0].selected = true;
      return json
      
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }
 


  useEffect(() => {
 
    fetchProduct()
  }, [])

  function handelSummit(e) {
    let saleItem=product.find(item=>{
      return item.productDBID==document.getElementById('eventSelected').value
    })

    let invoiceID=document.getElementById("invoiceID").value
    if (invoiceID==""){
      alert ('發票號碼未填寫')
      return
    }

      var formData = new FormData();
      formData.append("productDBID", saleItem.productDBID);
      formData.append("orderDate", document.getElementById("orderDate").value);
      formData.append("payment", document.getElementById("payment").value);
      formData.append("amount", document.getElementById("amount").value);
      formData.append("total", document.getElementById("total").value);
      formData.append("price", document.getElementById("price").value);
      formData.append("operator", operator);
      formData.append("invoiceID", invoiceID);
      formData.append("token", token);

      fetch(`${url}/appPost/addProductOrder`, {
        method: "POST",
        body: formData,
        mode: "cors",
      })
        .then((res) => {
          return res.json()

        }).then(() => {
          window.location.href = `/ProductListView`
        })

      // return window.location.href = `/SingleStudentView/${studentDBID}`
  }




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">新增商品銷售訂單</h5>
        </div>
        <div className="mt-2">
        

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-warning">訂單資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單日期
                      </span>
                </div>
                <input
                  type="date"
                  id="orderDate"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    商品名稱
                      </span>
                </div>
                <select id="eventSelected" className="custom-select" required 
                onChange={
                  (e)=>{
                    let productDBID=e.target.value
                    let selectEvent=product.find(item=>{
                      return item.productDBID==productDBID
                    })
                    console.log('productDBID',productDBID)
                    console.log(selectEvent,selectEvent)

                    document.getElementById('price').value=selectEvent.price
                    
                    let amount=document.getElementById('amount').value

                    let total=parseInt(amount,"10")*(parseInt(selectEvent.price,"10"))

                    document.getElementById('total').value=total
                    document.getElementById('taxPrice').innerHTML=`未稅 ${total*0.95} /稅金 ${total*0.05}`

                
                  }
                }
                >
                  
                  {product.map((item,index)=>{
                    return (
                      <option value={item.productDBID}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂購數量
                      </span>
                </div>
                <select id="amount" className="custom-select" required 
                onChange={(e)=>{
                  let amount=e.target.value
                  let price=document.getElementById('price').value
                  let total=parseInt(amount,"10")*(parseInt(price,"10"))
                    document.getElementById('total').value=total
                    document.getElementById('taxPrice').innerHTML=`未稅 ${total*0.95} /稅金 ${total*0.05}`
                }}>
                  {orderAmountNumber.map(item=>{
                    return  <option value={parseInt(item,"10")}>{item}</option>
                  })}
                  
                </select>
              </div>
            </div>


   

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    商品單價
                      </span>
                </div>
                <input
                  type="text"
                  id="price"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                 
                />
              </div>

              <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單總金額
                      </span>
                </div>
                <input
                  type="number"
                  id="total"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  value={0}
                
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    未稅/稅金
                      </span>
                </div>
                <div
                  id="taxPrice"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                
                />
              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    發票號碼
                      </span>
                </div>
                <input
                  type="text"
                  id="invoiceID"
                  className="form-control"
                 
                  aria-describedby="validationTooltipUsernamePrepend"
               
                />

              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    付款方式
                      </span>
                </div>
                <select id="payment" className="custom-select" required 
               >
                  <option value="信用卡">信用卡</option>
                  <option value="現金">現金</option>
                  <option value="匯款">匯款</option>
                  <option value="贈課">贈課</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
            </div>
          </div>

          

          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={handelSummit}
              className="btn btn-danger "
            >
              新增訂單
                </button>
            <Link to={`/`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default ProductOrderAdd;