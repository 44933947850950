import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";

import getDay from "../function/getDay";
import getUrl from "../function/getUrl";
import liff from '@line/liff';
import checkLogin from "../function/checkLogin.js";
const liffId="1571161345-geKvkm41"
const HomeLine = (props) => {
  const url = getUrl();
  const token = sessionStorage.getItem("token");
  const [name, setName] = useState(null);


  useEffect(() => {
    liff.init({ liffId: liffId }).then(()=>{
      liff.login({
        // 使用者登入後要去到哪個頁面
        redirectUri: 'https://good2swimos3beta-oi3as7ovga-de.a.run.app/homeline'
      });

     let user=liff.getProfile()
     setName(user.displayName)
     console.log(user)
    });
  }, []);
  //Home
  return (
    <>
      <div className="card" style={{ width: "18rem;" }}>
        <div className="card-body alert-info">
         <p>Home Line Page</p>
         <div>{name}</div>
        </div>
      </div>

    </>
  );
};

export default HomeLine;
