import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'

import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"

import getUrl from "../function/getUrl.js"
const token = sessionStorage.getItem("token")
//模版查詢
const GetClassTemplateList = (props) => {
  checkLogin()
  const [pickupWeekday, setPickupWeekDay] = useState("7")
  let title = ""
  let goLink = "/templateClassEdit/"
  let getDataURL = ""
  let url = getUrl()
  let count = 0
  const day_list = ['一', '二', '三', '四', '五', '六', '日'];

  //進度條

  const [classList, setClassList] = useState([])

  var imgStyle = {
    width: '20px',
    height: '20px',
  };


  function getSelect(e) {

    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        return optionFollow[i].value;
      }
    }
    return "";
  }

  function getData(pickupWeekday) {

    fetch(`${url}/appGet/getClassTemplateList?token=${token}`).then((res) => {
      return res.json();
    }).then(json => {
      let data = json
      if (pickupWeekday != "7") {
        data = json.filter(item => {
          return item.weekDay == pickupWeekday
        })
      }
      setClassList(data)


    })
  }



  useEffect(() => {
    getData("7")
  }, [])

  return (
    <>
      <div className="container text-center">
        <div className="card alert-info p-2 " >
          <div className="container vw-75" style={{ maxWidth: "500px" }}>
            <h5 className="m-1">周課表 模版</h5>
     
              <div className="container " style={{ maxWidth: "500px" }} style={{"width":"80%"}}>

                  <div className="input-group mt-1">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        星期
                  </span>
                    </div>

                    <select
                      id="searchWeek"
                      className="custom-select"
                      onChange={e => {

                        getData(e.target.value)
                      }}
                    >
                      <option selected value="7">全部</option>

                      <option value="0">一 </option>
                      <option value="1">二 </option>
                      <option value="2">三 </option>
                      <option value="3">四 </option>
                      <option value="4">五 </option>
                      <option value="5">六 </option>
                      <option value="6">日 </option>
                    </select>
                  </div>

              <div className="btn btn-info mt-1" style={{"width":"80%"}} onClick={()=>{
                window.location.href="/templateClassAdd"
              }}>新增課程</div>
           
                <div>
                </div>

              </div>

  

            <div>
            </div>

          </div>

        </div>

        <hr />


        <table class="table table-striped">
          <thead>
            <tr className="text-center">
              <th scope="row">#</th>

              <th scope="col">星期</th>
              <th scope="col">開課時間</th>
              <th scope="col">年紀</th>
              <th scope="col">上限</th>
             
              <th scope="col">App劃位</th>
              <th scope="col">開放體驗</th>
              <th scope="col">是否開課</th>

            </tr>
          </thead>
          <tbody className="text-center">
            {classList.map((item, index) => {
              count += 1
              return (

                <tr onClick={() => { window.location.href = `${goLink}${item['classDBID']}` }} style={{ textDecoration: 'none' }}>
                  <th scope="row">{count}</th>


                  <td>{day_list[item['weekDay']]}</td>
                  <td>{item['classTime']}</td>
                  <td>{item['age']}</td>
                  <td>{item['limit']}</td>
                
                  <td>{item['webApp']}</td>
                  <td>{item['trial']}</td>
                  <td>{item['available']}</td>
                </tr>

              )
            })}

          </tbody>
        </table>
      </div>
    </>
  )

}

export default GetClassTemplateList;