import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import pencilIcon from "../icon/pencil.svg";
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import FunBar from "./funBar.js";
import getUrl from '../function/getUrl'
import getDay from '../function/getDay'
import StudentCard from '../components/studentCard.js'

//大量取消課程
const url=getUrl()
const token=sessionStorage.getItem("token")
const SingleStudentView = (props) => {
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  let staffDBID=sessionStorage.getItem('staffDBID')
  let studentEdit = "/studentEdit/" + studentDBID;
  let goLink = "";
  let total = 0; //
  let classCount = 0; //總購課數
  let cancel = 0; //取缺數
  const today=getDay(0,"-")
  let noshow=0;//缺課
  let done = 0; //完課數
  let reservation = 0;
  const [student, setStudent] = useState(null);
  const [orderList, setOrderList] = useState(null);
  const [classList, setClassList] = useState(null);
  const [studentClassState, setStudentClassState] = useState(null);
  const [studentIDState, setStudentID] = useState(null);
  const [studentNameState, setStudentName] = useState(null);
  var imgStyle = {
    width: "20px",
    height: "20px",
  };
  var fontStyle = { 
    fontSize: "0.5em",
  };

  function goCancel(){
    let msg=`確定執行取消 ${studentNameState} 所有劃位？`
    if (!window.confirm(msg)) {
      window.location.href = `/SingleStudentView/${studentDBID}`
      return
  } 
    // alert(studentDBID)
    fetch(`${url}/appGet/allClassCancel?studentDBID=${studentDBID}&token=${token}&staffDBID=${staffDBID}`).then(res=>res.json()).then(json=>{
      console.log(json)
      window.location.href = `/SingleStudentView/${studentDBID}`
    }).catch(err=>{
      console.log(err)
      window.location.href = `/SingleStudentView/${studentDBID}`
    })
  }

  function getData() {
   

    fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {

        if (json["studentType"]=="體驗"){
          window.location.href=`/SingleStudentTrialView/${studentDBID}`
        }
        console.log(json);

        console.log(json["birthday"]);
        let age = birthdayToAge(json["birthday"], "/");
        setStudentID(json["studentID"]);
        setStudentName(json["name"]);
        

        

        let classArray = [];
        json["classList"].sort((a, b) => {
          var nameA = a.classDate;
          var nameB = b.classDate;
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
        let row=0
        for (let x in json["classList"]) {
          let getDate = json["classList"][x]["classDate"].substring(0, 10);
          var dateWeek = new Date(getDate).getDay();
          var day_list = ["日", "一", "二", "三", "四", "五", "六"];
          var week = day_list[dateWeek];

          let singleClass = "";
          
            
            if (json["classList"][x]["studentState"] =="劃位" && json["classList"][x]["classDate"]>=today){
              row+=1
              singleClass = (
                <tr className="text-muted text-center"  onClick={
                  ()=>{
                   window.location.href=`/SingleClassView/${json["classList"][x]["classDBID"]}`
                  }
                 }>
                  <th scope="row">{row}</th>
                  <td>
                    {json["classList"][x]["classDate"].substring(0, 10)} ({week})
                  </td>
                  <td>{json["classList"][x]["classTime"]}</td>
                  <td>{json["classList"][x]["age"]}</td>
                  <td>{json["classList"][x]["studentType"]}</td>
                  <td ><span className="text-info">{json["classList"][x]["studentState"]}</span></td>
                </tr>
              );
            }
          
          

          classArray.push(singleClass);

          switch (json["classList"][x]["studentState"]) {
            case "取消":
              cancel += 1;
              break;
            case "缺課":
              noshow += 1;
              break;
            case "完課":
              done += 1;
              break;
            case "劃位":
              reservation += 1;
              break;
          }
        }
        setClassList(classArray);
        total = noshow + done;
        let studentAnalysis = (
          <>
            <table className="table table-striped text-center vw-75">
              <thead className="text-muted">
                <tr>
                  <th scope="col">已劃位</th>
                  <th scope="col">可劃位</th>
                  <th scope="col">購課數</th>
                  <th scope="col">餘課數</th>
                  <th scope="col">缺課數</th>
                  <th scope="col">完課數</th>
                
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{reservation}</th>
                  <td>{classCount - done - reservation}</td>
                  <td>{classCount}</td>
                  <td>{classCount - done}</td>
                  <td>{noshow}</td>
                  <td>{done}</td>
                  
                </tr>
              </tbody>
            </table>
          </>
        );
        setStudentClassState(studentAnalysis);
      });
  }

  useEffect(() => {
    getData();
    document.getElementById("goCancelBtn").disabled=true
  }, []);

  return (
    <>
      <div className="container  text-center">
        <div className="bg-danger p-2 text-white rounded"><h4>一鍵大量取消排課</h4></div>
       
       
       
        <StudentCard studentDBID={studentDBID} />
    
        <div className="card alert-danger p-2 mt-1">
          <h5 className="m-1">已劃課記錄</h5>
        </div>
   
        <table className="table table-striped">
          <thead>
            <tr className="text-center mt-3">
              <th scope="row">#</th>
              <th scope="col">日期</th>
              <th scope="col">時間</th>
              <th scope="col">班別</th>
              <th scope="col">身分</th>
              <th scope="col">狀態</th>
            </tr>
          </thead>
          <tbody className="text-center">{classList}
          
          </tbody>
        </table>
      </div>
      <div className="mt-5 card border text-center">
      <h5 className="alert-danger text-center py-2">安全確認</h5>
      
      <div className="ml-auto mr-auto  py-2" style={{maxWidth:"500pt"}} >
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                     安全確認
                      </span>
                  </div>
                  
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="請輸入學員姓名"
                    onChange={(e)=>{
                      let value=e.target.value
                      console.log(value)

                      if (value==studentNameState){
                        document.getElementById("goCancelBtn").disabled=false
                      } else {
                        document.getElementById("goCancelBtn").disabled=true
                      }

                    }}
                    required
                  />
                </div>
                <button className="mt-2 py-2 btn btn-danger text-center" id="goCancelBtn" style={{width:"220pt"}} onClick={goCancel} >執行取消</button>
              </div>
              </div>
    </>
  );
};

export default SingleStudentView;
