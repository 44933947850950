
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
//課程模版 新增課程
const TemplateClassAdd = () => {
  checkLogin()
  let param = useParams(); //功能項目
 
  const [classData, setClassData] = useState({})
  const [classDate, setClassDate] = useState(null)
  const [week, setWeek] = useState(null)
  const day_list = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [staffList, setStaffList] = useState([])
  const [coachIDState,setCoachIDState]=useState(null)
  const url = getUrl()
  const today=getDay(0,"-")
  const token=sessionStorage.getItem("token")
  useEffect(() => {
    
    const fetchClassData = (classDBID) => {
      return fetch(`${url}/appGet/templateSingleClassView?classDBID=${classDBID}&token=${token}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json)
          return json

        })
    }




    const fetchData = async () => {
     
     
  
      let dateWeek = new Date(classDate).getDay()
      var weekValue = day_list[dateWeek]

      document.getElementById("week").value = "0"
      document.getElementById("limit").value = 6



    };

    fetchData()



  }, [])

  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function handleSummit(e) {
    let operator=sessionStorage.getItem("staffDBID")
   
    if (document.getElementById('classTime').value =="" || document.getElementById('classTime').value.length != 4){
      alert('開課時間錯誤')
      document.getElementById('classTime').value=""
      return 
    }
    if (document.getElementById('limit').value > 7 || document.getElementById('limit').value.length < 1 ){
      alert('人數上限錯誤')
      document.getElementById('limit').value=7
      return 
    }

    var formData = new FormData();
    formData.append("week",document.getElementById('week').value)
    formData.append("age", document.getElementById("age").value);
    formData.append("limit", document.getElementById("limit").value);
    formData.append("classTime", document.getElementById("classTime").value);
    formData.append("available", document.getElementById("available").value);
    formData.append("webApp", document.getElementById("webApp").value);
    formData.append("trial", document.getElementById("trial").value);
    formData.append("operator", operator);
    formData.append("token",token)
    // alert(document.getElementById('week').value)
    fetch(`${url}/appPost/addTemplateSingleClass`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
       
        return res.status

      }).then((status)=>{
        
       if (status!=200){
         alert('新增失敗')
       }
       window.location.href="/GetClassTemplateList"
       return 
      })

    return 
  }


  


  return (
    <>
 
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>

        <div className="card bg-info text-white p-2">
          <h5 className="m-1">新增課程模版  </h5>
        </div>

        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">課程基本資料區</div>
            <div className="card-body ">


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課星期
                      </span>
                  </div>
                  <select id="week" className="custom-select" required>
                    <option selected>請選擇...</option>
                    <option value="0">一</option>
                    <option value="1">二</option>
                    <option value="2">三</option>
                    <option value="3">四</option>
                    <option value="4">五</option>
                    <option value="5">六</option>
                    <option value="6">日</option>
         
                  </select>
       
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開課時間
                      </span>
                  </div>
                  <input
                    type="text"
                    id="classTime"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    placeholder="請填寫24小時制4碼 EX：1520"
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      班別年紀
                      </span>
                  </div>
                  <select id="age" className="custom-select" required>
                    <option value="1-2Y">1-2Y</option>
                    <option value="2-3Y">2-3Y</option>
                    <option value="2M">2M</option>
                    <option value="3-6M">3-6M</option>
                    <option value="3-7Y" selected>3-7Y</option>
                    <option value="6-12M">6-12M</option>
                    <option value="7-12Y">7-12Y</option>
                    <option value="備用">備用</option>
                    <option value="暑期班">暑期班</option>
                    <option value="水中攝影">水中攝影</option>
                    <option value="自由練習">自由練習</option>
                    <option value="體驗">體驗</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      人數上限
                      </span>
                  </div>
                  <input
                    type="text"
                    id="limit"
                    className="form-control"
                    aria-describedby="validationTooltipUsernamePrepend"
                    required
                 
                  />
                </div>
              </div>


              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      開放APP選課
                      </span>
                  </div>

                  <select id="webApp" className="custom-select" required>
                    <option value="Yes" selected>Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
 
              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開放體驗
                      </span>
                  </div>

                  <select id="trial" className="custom-select" required>
                    <option value="Yes" selected>Yes</option>
                    <option value="No" >No</option>
                  </select>
                </div>
              </div>
              <div className="m-1 ">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      是否開課
                      </span>
                  </div>

                  <select id="available" className="custom-select" required>
                    <option value="Yes" selected>Yes</option>
                    <option value="No" >No</option>
                  </select>
                </div>
              </div>

            </div>
          </div>

          
            <div className="card  mt-2 p-1" style={{ width: "100%" }}>
                <div
                  onClick={handleSummit}
                  className="btn btn-danger mt-1"
                >
                  新增
                </div>

                  <div
                    className="btn alert-dark mt-1" 
                    onClick={()=>{
                      window.location.href="/GetClassTemplateList"
                    }}
                  >
                    取消
                  </div>
            
              </div>
        

         
        </div>
      </div>
    </>
  )

};


export default TemplateClassAdd;