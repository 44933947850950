
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"

import checkLogin from "../function/checkLogin.js"


//課程銷售
const OrderAdd = () => {
  //銷售後 要同步調整 學員身分別
  // 體驗銷售 不得銷售給 正式學員
  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  console.log("studentDBID", studentDBID)
  const url = getUrl()
  const today = getDay(0, "-")
  const token = sessionStorage.getItem("token")
  const [studentData, setStudentData] = useState({})
  const operator = sessionStorage.getItem("staffDBID")

  const fetchStudentData = (studentDBID) => {
    return fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
      //設置 student Data
      return json
      console.log(json)
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }

  const fetchStudentLastDate=(studentDBID)=>{
    return fetch(`${url}/appGet/getStudentLastDate?studentDBID=${studentDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
      
      console.log(json)
      if (json[0]==null){
        return ""
      }
      return json[0].lastDate
      
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }



  useEffect(() => {

    
    const setStudent = async (studentDBID) => {
      let student = await fetchStudentData(studentDBID)
      let studentLastDate= await fetchStudentLastDate(studentDBID)
      
      console.log(studentLastDate)
      document.getElementById('title').innerHTML = `新增 ${student.name} 體驗追蹤記錄`
      document.getElementById('name').innerHTML = student.name
      document.getElementById('nickName').innerHTML = student.nickName
      document.getElementById('parent').innerHTML = student.parent
      document.getElementById('date').value = today



    }
    setStudent(studentDBID)
  
  }, [])

  function handelSummit(e) {
    
  
      let date=document.getElementById('date').value
      let contactType=document.getElementById('contactType').value
      let contact=document.getElementById('contact').value
      let result=document.getElementById('result').value
      let nextStep=document.getElementById('nextStep').value
      console.log(studentDBID,date,contactType,contact,result,nextStep)
      var formData = new FormData();
      formData.append("studentDBID", studentDBID);
      formData.append("date", date);
      formData.append("contactType", contactType);
      formData.append("contact", contact);
      formData.append("result", result);
      formData.append("nextStep", nextStep);
      formData.append("token", token);
      formData.append("operator",operator);

      fetch(`${url}/appPost/addStudentTrialFollow`, {
        method: "POST",
        body: formData,
        mode: "cors",
      })
        .then((res) => {
          return res.json()

        }).then(() => {
          window.location.href = `/SingleStudentTrialView/${studentDBID}`
        })

    
  }




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">新增 {studentData.name} 體驗追蹤記錄</h5>
        </div>
        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">基本資料區</div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                      </span>
                </div>
                <div

                  id="name"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    暱稱
                      </span>
                </div>
                <div

                  id="nickName"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    家長
                      </span>
                </div>
                <div

                  id="parent"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>


          </div>

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-warning">追蹤記錄資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    追蹤日期
                      </span>
                </div>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    連絡方式
                      </span>
                </div>
                <select id="contactType" className="custom-select" required 
                >
                    <option >電話</option>    
                    <option >Line</option> 
                    <option >FB</option> 
                    <option >其他</option>                      
                 
                </select>
              </div>
            </div>


            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    連絡內容
                      </span>
                </div>
                <textarea
                  
                  id="contact"
                  cols="50" rows="3"
                  className="form-control"
                 
                  aria-describedby="validationTooltipUsernamePrepend"
               
                />

              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    連絡結果
                      </span>
                </div>
                <textarea
                  
                  id="result"
                  cols="50" rows="3"
                  className="form-control"
                 
                  aria-describedby="validationTooltipUsernamePrepend"
               
                />

              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    狀態
                      </span>
                </div>
                <select id="nextStep" className="custom-select" required 
               >
                  <option >繼續追蹤</option>
                  <option >結案</option>
                </select>
              </div>
            </div>
           
          </div>

         

          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={handelSummit}
              className="btn btn-danger "
            >
              新增記錄
                </button>
            <Link to={`/SingleStudentView/${studentDBID}`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default OrderAdd;