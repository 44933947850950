
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//進度回饋讀取
const SingleClassFeedBackReadOnly = () => {
  const $ = window.$
  checkLogin()

  let param = useParams(); //功能項目
  let classRecordDBID = param.classRecordDBID;
  const url = getUrl()
  const token = sessionStorage.getItem("token")
  const coach = sessionStorage.getItem("staffDBID")
  const [coachName, setCoachName] = useState(null)
  const level = sessionStorage.getItem('level')
  const [classProcessOption, setClassProcessOption] = useState([])
  const [classDBID, setClassDBID] = useState(null)
  const [studentDBID,setStudentDBID]=useState(null)
  const [name,setName]=useState(null)


  const fetClassProcessList = (classProcess) => {
    return fetch(`${url}/appGet/getClassProcess?token=${token}`).then((res) => {
      return res.json()
    }).then(json => {
      // console.log(json)
      let result = []
      for (let i in json) {
        // console.log(classProcess, json[i])
        if (json[i].classProcess == classProcess) {
          result.push(json[i])
        }
      }
      // console.log(result)

      sessionStorage.setItem('classProcessData', JSON.stringify(result))
      return result
    })
  }

  function saveFeedBack() {

    let coachFeedBack = new Map()
    let checkBoxArray = $("input[type=checkbox]")
    for (let x in checkBoxArray) {
      console.log(checkBoxArray[x].value)
      if (checkBoxArray[x].checked == true) {
        console.log(checkBoxArray[x].value)


        let optionSelect = getSelect(document.getElementById(`select${x}`))
        // coachFeedBack[checkBoxArray[x].value] = optionSelect[0]
        coachFeedBack.set(checkBoxArray[x].value, optionSelect[0])

      }
    }

    if (coachFeedBack.size == 0) {
      if (!window.confirm('沒有選擇回饋動作項目哦！確認儲存記錄')) {
        return
      }

    }
    //以下處理回饋儲存動作

    let comments = document.getElementById('comments').value
    console.log(classRecordDBID, coachFeedBack, comments)
    var formData = new FormData();
    formData.append("comments", comments);
    formData.append("classRecordDBID", classRecordDBID);
    formData.append("coach", coach);
    formData.append("token", token);
    fetch(`${url}/appPost/updateClassRecordComments`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })




    //2. 新增記錄
    for (let i in coachFeedBack) {
      console.log(classRecordDBID, coachFeedBack.key, coachFeedBack.value)
    }
    //轉換 map to Json
    const actionJson = Object.fromEntries(coachFeedBack)

    var actionformData = new FormData();
    actionformData.append("actionJson", JSON.stringify(actionJson));
    actionformData.append("classRecordDBID", classRecordDBID);
    actionformData.append("coach", coach);
    actionformData.append("token", token);
    fetch(`${url}/appPost/updateCoachFeedBack`, {
      method: "POST",
      body: actionformData,
      mode: "cors",
    })

    window.location.href = `/SingleClassViewForCoach/${classDBID}`
  };

  //捕捉 select中被選的option
  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  const getReport = async () => {

    fetch(`${url}/appGet/getSingleClassRecord?classRecordDBID=${classRecordDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log(json)
        return json


      });


  }


  function checkOptionIsInData(action, actionDataInMysql) {
    //檢查是否已經在既有的資料庫中，這個動作被評分過
    for (let i in actionDataInMysql) {
      if (actionDataInMysql[i].action == action) {
        return [true, actionDataInMysql[i].level]
        break;
      }
    }
    return false
  }


  const makeActionOption = async (classProcess, actionArray) => {
    //classProcess本課程 之 action
    //actionArray 之前已存的選項
    // console.log(actionArray)
    let classProcessList = await fetClassProcessList(classProcess)
    let result = []
    // console.log(classProcessList)

    //整理已在的action 動作與 option 選項
    for (let i in classProcessList) {
      let thisActionName = classProcessList[i].action
      let actionAlreadyHas = actionArray.find(item => {
        return item.action == thisActionName
      })

      let tempResult = {}
      // let optionDefault = [{ optionName: '待加強', optionSelect: false }, { optionName: '完成', optionSelect: false }, { optionName: '傑出', optionSelect: false },]
       let optionDefault = [{ optionName: '', optionSelect: false }]
      tempResult['actionName'] = classProcessList[i].action
      if (actionAlreadyHas) {

        for (let x in optionDefault) {
          tempResult['actionSelect'] = true
        
          if (optionDefault[x].optionName == actionAlreadyHas.level) {
            optionDefault[x].optionSelect = true
            break;
          } else {
            optionDefault.push({ optionName: actionAlreadyHas.level, optionSelect: true })
            break;
          }
        }

      } else {
        tempResult['actionSelect'] = false
      }
      tempResult['option'] = optionDefault
      result.push(tempResult)

    }

    //整理不存在目前的action 動作與 option 選項
    for (let i in actionArray) {
      // console.log(actionArray[i].action)
      let thisActionName = actionArray[i].action
      let actionAlreadyHas = classProcessList.find(item => {
        return item.action == thisActionName
      })

      let tempResult = {}
      // let optionDefault = [{ optionName: '待加強', optionSelect: false }, { optionName: '完成', optionSelect: false }, { optionName: '傑出', optionSelect: false },]
      let optionDefault = [{ optionName: '', optionSelect: false }]
      tempResult['actionName'] = thisActionName
      if (!actionAlreadyHas) {
        //假如不存在
        tempResult['actionSelect'] = true
        for (let x in optionDefault) {
          // console.log(actionArray[i].level)
          if (optionDefault[x].optionName == actionArray[i].level) {
            optionDefault[x].optionSelect = true
            break;
          } else {
            optionDefault.push({ optionName: actionArray[i].level, optionSelect: true })
            break;
          }

        }

        tempResult['option'] = optionDefault
        result.push(tempResult)

      }

    }
    // result.map(item => {
    //   console.log(item)
    // })

    return result
  }

  const getData = async () => {
    //取回所有的回饋內容，並且更新至動作項目，如果選項不存在，則以另外新增額外選項方式存在。
    let data = await fetch(`${url}/appGet/getSingleClassRecord?classRecordDBID=${classRecordDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
         console.log(json)
        return json


      });
    let json = data[0]
    setCoachName(data[0].coachName)
    let actionArray = data[1]

    // console.log(json)
    // console.log(actionArray)

    makeActionOption(json.classProcess, actionArray)
    // let classProcessList = await fetClassProcessList(json.classProcess)
    let classProcessList = await makeActionOption(json.classProcess, actionArray)

    // console.log(classProcessList)
    // fetClassProcessList(json.classProcess)

    let optionData = []

    for (let i in classProcessList) {
      // console.log("value", classProcessList[i].actionName)
      let singleOption = <tr >
        <td>{parseInt(i, "10") + 1}</td>
        <td>
          <div className="form-check-input text-muted checkBoxArray " id={"exampleCheck" + i} key={i}   >{classProcessList[i].actionName}</div>
        

        </td>
        <td className="text-center">
         
              {classProcessList[i].option.map(item => {
                if (item.optionSelect){
                  return <span>{item.optionName}</span>
                }
               
              })}
       
        
        </td>
      </tr>

      optionData.push(singleOption)
    }
    //動作清單及評估
    setClassProcessOption(optionData) 
    setStudentDBID(json.studentDBID)
    setName(json.name)
    document.getElementById('classDate').value = json.classDate
    document.getElementById('name').innerHTML = json.name
    document.getElementById('classTime').innerHTML = json.classTime
    document.getElementById('age').innerHTML = json.age
    document.getElementById('classProcess').innerHTML = json.classProcess
    document.getElementById('comments').innerHTML = json.comments
    setClassDBID(json.classDBID)
    //返回整理後的清單 
    return classProcessList
  }
  useEffect(async () => {

    await getData()
    

  }, [])




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-warning text-dark p-2">
          <h5 className="m-1" id="title">學習進度回饋 回饋者: {coachName} </h5>
        </div>
        <div className="mt-2">


          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-dark">學員課程資料</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                      </span>

                </div>
                <div

                  id="name"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    日期
                      </span>

                </div>
                <input

                  id="classDate"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>


            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    時段
                      </span>
                </div>
                <div id="classTime" className="form-control"  >

                </div>
              </div>
            </div>


            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    班別
                      </span>
                </div>
                <div id="age" className="form-control"  >

                </div>
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    主題
                      </span>
                </div>
                <div id="classProcess" className="form-control"  >

                </div>
              </div>
            </div>


          </div>
          <div className=" mt-1 card p-2"> <small className="ml-2 text-muted " >教學動作評估</small>
            <table className="table " >

              <thead>
                <tr>

                  <th >#</th>
                  <th >動作 </th>
                  <th className="text-center" style={{ "width": "50%" }}>評估</th>
                </tr>
              </thead>
              <tbody>
                {classProcessOption}
              </tbody>
            </table>


          </div>

          <div className="mt-1 card p-2 "> <small className="ml-2 text-muted" >學習進度回饋</small>
            <div className="input-group">

              <div className="input-group-prepend">
                <span
                  className="input-group-text alert-danger"
                  id="validationTooltipUsernamePrepend"
                >
                  備註
                      </span>
              </div>
              <div className="form-control" id="comments" rows="5" aria-describedby="validationTooltipUsernamePrepend"></div>


            </div>
          </div>




          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
    
           
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
                 onClick={()=>{
                  window.location.href = `/SingleClassViewForCoach/${classDBID}`
                 }}
              >
                回本堂課程
                  </div>
                       
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
                 onClick={()=>{
                  window.location.href = `/singleStudentViewForActionHistory/${studentDBID}`
                 }}
              >
                回{name}學習歷程
                  </div>
  
          </div>

        </div>
      </div>
    </>
  )

};



export default SingleClassFeedBackReadOnly;