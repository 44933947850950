import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import getUrl from "../function/getUrl.js"
import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import birthdayToAge from '../function/birthdayToAge.js'


const url = getUrl()
//快速查詢學生 for 安排課程
const GetStudentListComponent = (props) => {
    console.log('store', props.store)
    const [store, setStore] = useState(props.store)
    const [studentState, setStudentState] = useState('all')

    let classDBID = props.classDBID
    let title = ""
    let goLink = ""
    let getDataURL = `${url}/getStudentByName`
    let token=sessionStorage.getItem('token')
    //進度條
    let loadingState = <>
        <h5 className="text-info mt-5">資料載入中...</h5>
        <div className="progress w-50">
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '100%' }}></div>
        </div>
    </>
    const [studentList, setStudentList] = useState(null)

    var imgStyle = {
        width: '20px',
        height: '20px',
    };


    function handleSearch(e) {
        setStudentList(loadingState)
        let name = document.getElementById('searchName').value

        let studentArray = []

        fetch(`${getDataURL}?name=${name}&token=${token}`).then((res) => {
            return res.json();
        }).then(json => {
            console.log(json)
            // let studentState=document.getElementById('studentState').value
            for (let x in json) {
                console.log("here", json[x])
                let age = birthdayToAge(json[x]['birthday'], "/")
                let singleStudent =
                    <tr onClick={() => {
                        let tempArray=[]
                        tempArray.push(json[x])
                        store.dispatch({ type: "add", studentList:tempArray})
                        console.log('store update',store.getState())
                    }} className="text-muted" data-dismiss="modal" style={{ textDecoration: 'none' }}>
                        <th scope="row">{parseInt(x, 10) + 1}</th>
                        <td>{json[x]['studentID']}</td>
                        <td>{json[x]['name']}</td>
                        <td>{json[x]['nickName']}</td>
                        <td>{json[x]['studentType']}</td>
                        <td>{json[x]['birthday']}</td>
                        <td>{age[0]}</td>
                        <td>{json[x]['parent']}</td>
                    </tr>

                studentArray.push(singleStudent)



            }

        }).then(() => {
            setStudentList(studentArray)
        })
    }



    return (
        <>
            <div className="container text-center mt-3">
                <div className="card alert-info p-2 " >
                    <div className="container vw-75" style={{ maxWidth: "1200px" }}>
                        <h5 className="m-1">{title}</h5>

                        <h6 className="m-1 text-muted">挑選學員</h6>
                        <div className="card-body">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1"> <img src={searchIcon} style={imgStyle} alt="search" /> Search</span>
                                </div>
                                <input type="text" className="form-control " id="searchName" placeholder="學員、家長姓名" aria-label="Username" aria-describedby="basic-addon1" />
                                <button className="btn btn-info w-20" onClick={handleSearch} ><img src={reloadIcon} style={imgStyle} /> 送出</button>
                                

                            </div>


                        </div>
                        <div>
                        </div>

                    </div>

                </div>

                <hr />


                <table className="table table-striped">
                    <thead>
                        <tr className="text-center">
                            <th scope="row">#</th>
                            <th scope="col">編號</th>
                            <th scope="col">姓名</th>
                            <th scope="col">暱稱</th>
                            <th scope="col">身分</th>
                            <th scope="col">生日</th>
                            <th scope="col">年紀</th>
                            <th scope="col">家長</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {studentList}

                    </tbody>
                </table>
            </div>
        </>
    )

}

export default GetStudentListComponent;