import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js";
import FunBarClassForCoach from "./funBarClassForCoach";
const url = getUrl();
const token = sessionStorage.getItem("token");
//課程畫面元件
const ClassDataViewComponentForCoach = (props) => {
  const classDBID = props.classDBID;
  const [classData, setClassData] = useState({});

  const fetCoachData = () => {
    return fetch(`${url}/appGet/getStaffData?token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });
  };

  //查詢課程的星期
  const getWeekDay = (classDate) => {
    const dateWeek = new Date(classDate).getDay();
    const day_list = ["日", "一", "二", "三", "四", "五", "六"];
    return day_list[dateWeek];
  };

  useEffect(async () => {
    const staffData = await fetCoachData();
   
    fetch(`${url}/appGet/singleClassView?classDBID=${classDBID}&token=${token}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //處理星期顯示
        const dateWeek = new Date(data.classDate).getDay();
        const day_list = ["日", "一", "二", "三", "四", "五", "六"];
        data.week = day_list[dateWeek];

        staffData.map((item, index) => {
         
          if (item.staffDBID == data.coachID) {
            data.coach = item.name;
          }
          if (item.staffDBID == data.assistantID) {
            data.assistant = item.name;
          }
        });

       
        setClassData(data);
      });
  }, []);

  return (
    <>
      <FunBarClassForCoach
        classDBID={classData["classDBID"]}
        classDate={classData["classDate"]}
      />
      <div className="card  border-1  w-80">
        <h5 className=" card-header mb-1 bg-dark text-light">學員學習進度回饋表</h5>
        <div className="card p-1 m-2 alert-info">
          <div className="row">
            <div className="col  ">
              <div className="card-body rounded border-0 ">
                <h6 className="card-title">課桯基本資料</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">
                    日期：{classData["classDate"]}
                  </li>
                  <li className="list-group-item">星期：{classData["week"]}</li>
                  <li className="list-group-item">
                    時間：{classData["classTime"]}
                  </li>
                  <li className="list-group-item">
                    開課：{classData["available"]}
                  </li>
                  <li className="list-group-item">
                    APP：{classData["webApp"]}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col ">
              <div className="card-body rounded border-0 ">
                <h6 className="card-title">課程教學</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">班別：{classData["age"]}</li>
                  <li className="list-group-item">
                    主題：{classData["classProcess"]}
                  </li>
                  <li className="list-group-item">
                    教練：{classData["coach"]}
                  </li>
                  <li className="list-group-item">
                    助教：{classData["assistant"]}
                  </li>
                  {/* <li className="list-group-item">
                    體驗：{classData["trial"]}
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col ">
              <div className="card-body  rounded border-0 ">
                <h6 className="card-title">人數狀況</h6>

                <ul className="list-group list-group-flush text-left ">
                  <li className="list-group-item">
                    上限：{classData["limit"]}
                  </li>
                  <li className="list-group-item">
                    劃位：{classData["booking"]}
                  </li>
                  <li className="list-group-item">
                    候補：{classData["backup"]}
                  </li>
                  <li className="list-group-item">
                    取消：{classData["cancel"]}
                  </li>
                  <li className="list-group-item">完課：{classData["done"]}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassDataViewComponentForCoach;
