
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//課程訂單 請假延長
/*
架構說明
1.一定要指定延長的訂單編號
2. 最短周數為1周 數量為8 合計為2個月
3. 一定填寫原因，及上傳証明
4.存檔operator
5。延長日期為 原訂單的周期直接加上延長日期的天數

*/
const OrderExtend = () => {
  //銷售後 要同步調整 學員身分別
  // 體驗銷售 不得銷售給 正式學員
  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  let param = useParams(); //功能項目
  let orderDBID = param.orderDBID;

  const url = getUrl()

  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const level = sessionStorage.getItem("level")
  const [studentDBID, setStudentDBID] = useState([])
  const [name,setName]=useState(null)
  const [result,setResult]=useState({})


  const getOrderData = async (orderDBID) => {
    let result = await fetch(`${url}/appGet/orderView?orderDBID=${orderDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
      setResult(json[0])
      return json[0]

    }).catch((e) => {
      alert('伺服器錯誤')
    })

    setStudentDBID(result.studentDBID)
    console.log(result)

    document.getElementById('title').innerHTML = ` ${result.name} 課程訂單`
    document.getElementById('name').innerHTML = result.name
    setName(result.name)
    document.getElementById('nickName').innerHTML = result.nickName
    document.getElementById('parent').innerHTML = result.parent
    document.getElementById('orderDate').innerHTML = result.orderDate
    document.getElementById('classStartDate').innerHTML = result.classStartDate
    document.getElementById('eventName').innerHTML = result.eventName


    let tempDate=new Date(result.classStartDate)
     
      let classEndDate=new Date(tempDate.setDate(tempDate.getDate() + parseInt(result.classDays,"10")+parseInt(result.classExtend,"10")))

    // document.getElementById('amount').innerHTML = result.amount
    document.getElementById('classAmount').innerHTML = result.classAmount
    document.getElementById('classDays').innerHTML = result.classDays
    // document.getElementById('eventPrice').innerHTML = result.eventPrice
    document.getElementById('total').innerHTML = result.total
    document.getElementById('invoiceID').innerHTML = result.invoiceID
    document.getElementById('payment').innerHTML = result.payment
    document.getElementById('classStartDate').innerHTML = result.classStartDate
    document.getElementById('memo').innerHTML = result.memo
    // document.getElementById('classExtend').innerHTML = result.classExtend
    document.getElementById('classEndDate').innerHTML = `${classEndDate.getFullYear()}-${classEndDate.getMonth()+1}-${classEndDate.getDate()}`
    result.classEndDate=`${classEndDate.getFullYear()}-${classEndDate.getMonth()+1}-${classEndDate.getDate()}`
    document.getElementById('NewclassEndDate').innerHTML=`${classEndDate.getFullYear()}-${classEndDate.getMonth()+1}-${classEndDate.getDate()}`

    

  };



  const addDays = (days) => {
    var dat = new Date(this.valueOf()); // (1)
    dat.setDate(dat.getDate() + days);  // (2)
    return dat
  }

  useEffect(() => {

    getOrderData(orderDBID)
  }, [])





  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">  課程訂單檢視</h5>
        </div>
        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">基本資料區</div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                      </span>
                </div>
                <div
                  id="name"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    暱稱
                      </span>
                </div>
                <div

                  id="nickName"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    家長
                      </span>
                </div>
                <div

                  id="parent"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
          </div>
        </div>
        <div className="card  mt-2 " style={{ width: "100%" }}>
        <div className="card-header alert-warning">訂單資料區</div>
        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂單日期
                      </span>
            </div>

            <div

              id="orderDate"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
          </div>
        </div>

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                專案名稱
                      </span>
            </div>

            <div

              id="eventName"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
          </div>
        </div>

        {/* <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂購數量
                      </span>
            </div>
            <div

              id="amount"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div> */}

        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                專案堂數
                      </span>
            </div>
            <div

              id="classAmount"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                課程天數
                      </span>
            </div>
            <div

              id="classDays"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>
{/* 
        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
              專案單價
                      </span>
            </div>
            <div

              id="eventPrice"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div> */}


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                訂單總金額
                      </span>
            </div>
            <div

              id="total"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
              發票號碼
                      </span>
            </div>
            <div

              id="invoiceID"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>


        <div className="m-1 ">
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text alert-warning"
                id="validationTooltipUsernamePrepend"
              >
                付款方式
                      </span>
            </div>
            <div

              id="payment"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

          </div>
        </div>





        
          </div>
  
          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-danger">課程資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程啟用日期
                      </span>
                </div>
            <div

              id="classStartDate"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div>
            {/* <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程展延
                      </span>
                </div>
                <div

              id="classExtend"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div> */}

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程到期日
                      </span>
                </div>
                <div

              id="classEndDate"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div>
   

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單備註
                      </span>
                </div>
                <div

              id="memo"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />

              </div>
            </div>
          </div>

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-success">請假展延資料</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-success"
                    id="validationTooltipUsernamePrepend"
                  >
                    延長周數
                      </span>
                </div>
                <select id="extendWeeks" className="custom-select" required onChange={(e)=>{
                  let value=e.target.value
                  console.log("option value:",value)
                  let classStartDate=document.getElementById('classStartDate').value
                  console.log('classStartDate',result.classStartDate)
                  let tempDate=new Date(result.classStartDate )
                  let newClassEndDate=new Date(tempDate.setDate(tempDate.getDate()+(parseInt(value,"10")*7)+parseInt(result.classDays,"10")))
                  let NewClassEndDate=newClassEndDate.getFullYear()+"-"+(newClassEndDate.getMonth()+1)+"-"+newClassEndDate.getDate()

                  console.log('newClassEndDate',newClassEndDate,newClassEndDate.getFullYear(),newClassEndDate.getMonth()+1,newClassEndDate.getDate())
                  console.log('NewClassEndDate',NewClassEndDate)
                  document.getElementById('NewclassEndDate').innerText=NewClassEndDate
                }}>
                   <option value="0" selected>請選擇</option>
                  <option value="1" >1周</option>
                  <option value="2">2周</option>
                  <option value="3">3周</option>
                  <option value="4">4周</option>
                  <option value="5">5周</option>
                  <option value="6">6周</option>
                  <option value="7">7周</option>
                  <option value="8">8周</option>
                </select>
              </div>
            </div>
            {/* <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-success"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程展延
                      </span>
                </div>
                <div

              id="classExtend"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div> */}

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-success"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程延長後到期日
                      </span>
                </div>
                <div

              id="NewclassEndDate"
              type="date"
              className="form-control"
              aria-describedby="validationTooltipUsernamePrepend"

            />
              </div>
            </div>
   

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-success"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請延長原因
                      </span>
                </div>
                <textarea class="form-control" id="extendMemo" rows="3"  aria-describedby="validationTooltipUsernamePrepend"></textarea>
               

              </div>
            </div>
          </div>
    <div className="m-3">
    <div className="input-group">
   

      <div className="btn btn-danger ml-3 mr-auto" style={{"width":"40%"}}  onClick={()=>{

        
        
        let extendWeeks=document.getElementById('extendWeeks').value
      if (extendWeeks==0){
        alert('周數未選擇')
        return
      }

      if (extendWeeks>3){
        if( !window.confirm('超過1個月 請確認已核備通過？')){
          return
        }
        
      }


      let extendMemo=document.getElementById('extendMemo').value
      if (extendMemo.length < 10){
        alert('申請延長原因請描述')
        return
      }


       if( !window.confirm('請留意每一筆申請延長期限，請必須再三確認其必要性，並在每月會議中，逐筆報告，確定送出申請？')){
        return
      }

      let extendOrder={
        "operator":operator,
        "momo":extendMemo,
        "studentDBID":result.studentDBID,
        "classStartDate": result.classEndDate,
        "classDays":parseInt(extendWeeks,"10")*7,

         }

         var formData = new FormData();

        
         formData.append("orderDBID", orderDBID);
         formData.append("studentDBID", studentDBID);
         formData.append("classDays", parseInt(extendWeeks,"10")*7);
         formData.append("classStartDate", result.classEndDate);
         formData.append("memo", extendMemo);
         formData.append("operator", operator);
         formData.append("token", token);
   
         fetch(`${url}/appPost/orderExtend`, {
           method: "POST",
           body: formData,
           mode: "cors",
         })
           .then((res) => {
             return res.json()
   
           }).then(() => {
             alert('申請作業完成')
             window.location.href = `/SingleStudentView/${studentDBID}`
           })

         console.log('extendOrder',extendOrder)
    }}>送出申請</div>

<div className="btn alert-dark ml-auto mr-auto" style={{"width":"40%"}} onClick={()=>{
      window.location.href=`/SingleStudentView/${studentDBID}`
    }}>取消</div>
    
    </div>
   
   
    </div>
   
          </div>
      

    

      
    </>
  )

};



export default OrderExtend;