
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//個案核備
const ReportAdd = () => {

  checkLogin()
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  console.log("studentDBID", studentDBID)
  const url = getUrl()
  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const [name,setName]=useState(sessionStorage.getItem('name'))


 





  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title">個案核備 申請人 {name} </h5>
        </div>
        <div className="mt-2">
       

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-dark">個案融通內容</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請日期
                      </span>
                     
                </div>
                <input 
                 
                 id="orderDate"
                 className="form-control"
                 aria-describedby="validationTooltipUsernamePrepend"
                value= {getDay(0,"-")}
               />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    申請類別
                      </span>
                </div>
                <select id="reportType" className="custom-select" required >
                  <option>請選擇</option>
                  <option>訂單變更</option>
                  <option>上課報到</option>
                  <option>學生資料</option>
                  <option>申請長假/延期</option>
                  <option>其他</option>
                  
                </select>
              </div>
            </div>

           

            <div className="m-1 "> <small className="ml-2 text-muted" >請詳加描述個案內容與需求</small>
              <div className="input-group">
               
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-dark"
                    id="validationTooltipUsernamePrepend"
                  >
                    變更內容說明
                      </span>
                </div>
                <textarea class="form-control" id="context" rows="3"  aria-describedby="validationTooltipUsernamePrepend"></textarea>
               

              </div>
            </div>




          </div>

         

          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={()=>{

                let reportType=document.getElementById('reportType').value
                if (reportType=="請選擇"){
                  alert('請選擇個案類別')
                  return
                }

                
                let context=document.getElementById('context').value

                if (context.length<10){
                  alert('請填寫個案申請描述唷！')
                  return
                }

                if(!window.confirm('內容確定無誤，要申請了嗎？')){
                  return 
                }

                var formData = new FormData();
                formData.append("staffDBID", operator);
                formData.append("name", name);
                formData.append("reportType", reportType);
                formData.append("context", context);
                formData.append('token',token)

                fetch(`${url}/appPost/addReport`, {
                  method: "POST",
                  body: formData,
                  mode: "cors",
                })
                  .then((res) => {
                    return res.json()
          
                  }).then(() => {
                    alert('受理申請中')
                    window.location.href = `/home`
                  })


              }}
              className="btn btn-danger "
            >
              提出申請
                </button>
            <Link to={`/home`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default ReportAdd;