 function dateDiff (sDate1, sDate2) { // sDate1 和 sDate2 是 2016-06-18 格式
        var oDate1 = new Date(sDate1);
        var oDate2 = new Date(sDate2);
        if (oDate1 > oDate2 ){
            var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
            return iDays 
        }else{
            var iDays = parseInt(Math.abs(oDate2 - oDate1) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
           
            return iDays * -1
        } 
       
       
    };

export default dateDiff;