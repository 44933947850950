
import React, {
  useState,
  useEffect,
  useCallback,
  updateState,
  useReducer,
  useContext,
} from "react";
import getUrl from "../function/getUrl.js"
import FunBarClass from "./funBarClass.js";
import { Link, useParams } from "react-router-dom";
import getDay from "../function/getDay.js"
import dateDiff from "../function/dateDiff.js"
import checkLogin from "../function/checkLogin.js"
import checkLevel from "../function/checkLevel.js"

//課程銷售
const OrderAdd = () => {
  //銷售後 要同步調整 學員身分別
  // 體驗銷售 不得銷售給 正式學員
  //非本人時且權限為1時，不得更改密碼
  checkLogin()
  let param = useParams(); //功能項目
  let studentDBID = param.studentDBID;
  console.log("studentDBID", studentDBID)
  const url = getUrl()
  const today = getDay(0, "-")
  const token = sessionStorage.getItem("token")
  const operator = sessionStorage.getItem("staffDBID")
  const level = sessionStorage.getItem("level")
  const [salesEvent, setSalesEvent] = useState([])
  const [studentData, setStudentData] = useState({})
  const [eventDBID,setEventDBID]=useState(null)
  const orderAmountNumber=["1","2","3","4","5","6","7","8","9"]

  const fetchStudentData = (studentDBID) => {
    return fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
      //設置 student Data
      return json
      console.log(json)
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }

  const fetchStudentLastDate=(studentDBID)=>{
    return fetch(`${url}/appGet/getStudentLastDate?studentDBID=${studentDBID}&token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
      
      console.log(json)
      if (json[0]==null){
        return ""
      }
      return json[0].lastDate
      
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }

  const fetchSalesEvent=()=>{
    return fetch(`${url}/appGet/getSalesEvent?token=${token}`).then((res) => {
      return res.json()
    }).then((json) => {
     
  

      json.map((item,index)=>{
        json[index].eventPrince=parseInt(json[index].eventPrince,"10")
      })

      json.sort((a,b)=>{
        var nameA = a.eventPrince; 
        var nameB = b.eventPrince; 
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      return 0;
      })
      console.log(json)
      setSalesEvent(json)

      // 初始化 專案選項
      json.map(item=>{
        if (item.forTrial=="true" &&item.alive=="true"){
          let eventOption = document.getElementById("eventSelected").options;
          document.getElementById('classAmount').value=item.classAmount
          document.getElementById('classDays').value=item.classDays
          document.getElementById('eventSelected').value=item.eventName
          document.getElementById('eventPrice').value=parseInt(item.eventPrice,"10")
          document.getElementById('amount').value = 1
          document.getElementById('total').value = document.getElementById('amount').value*document.getElementById('eventPrice').value
          let total= document.getElementById('total').value
          eventOption[0].selected = true;
          document.getElementById('taxPrice').innerHTML=`未稅 ${total*0.95} /稅金 ${total*0.05}`

        }
        
      })
      
      return json
      
    }).catch((e) => {
      alert('伺服器錯誤')
    })
  }
 
  const addDays = (days) =>{
    var dat = new Date(this.valueOf()); // (1)
    dat.setDate(dat.getDate() + days);  // (2)
    return dat
  }

  useEffect(() => {

    
    const setStudent = async (studentDBID) => {
      let student = await fetchStudentData(studentDBID)
      let studentLastDate= await fetchStudentLastDate(studentDBID)
      
      console.log(studentLastDate)
      document.getElementById('title').innerHTML = `新增 ${student.name} 體驗付款`
      document.getElementById('name').innerHTML = student.name
      document.getElementById('nickName').innerHTML = student.nickName
      document.getElementById('parent').innerHTML = student.parent
      document.getElementById('orderDate').value = today
      document.getElementById('classStartDate').value = today
      document.getElementById('classDays').disabled = true
      document.getElementById('classAmount').disabled = true
      document.getElementById('eventPrice').disabled=true
      

      document.getElementById('amount').value = 1
      document.getElementById('total').disabled=true 
      //測試日期
      let classDays=document.getElementById('classDays').value
      let startDate=document.getElementById('classStartDate').value
      
      let tempDate=new Date(startDate)
      console.log(tempDate,tempDate.getDate())
      let classEndDate=new Date(tempDate.setDate(tempDate.getDate() + parseInt(classDays,"10")))
      console.log(classEndDate,classEndDate.getFullYear(),classEndDate.getMonth()+1,classEndDate.getDate())
      document.getElementById('classEndDate').innerHTML = `${classEndDate.getFullYear()}/${classEndDate.getMonth()+1}/${classEndDate.getDate()}`
      document.getElementById('classEndDate').disabled = true
    }
    setStudent(studentDBID)
    fetchSalesEvent()
  }, [])

  function handelSummit(e) {
    let saleItem=salesEvent.find(item=>{
      return item.eventDBID==document.getElementById('eventSelected').value
    })

    let invoiceID=document.getElementById("invoiceID").value
    if (invoiceID==""){
      alert ('發票號碼未填寫')
      return
    }
    let startDate=document.getElementById('classStartDate').value
    let newStartDate=startDate.toString().replaceAll("-","/")
    console.log("newStartDate",newStartDate)
      console.log(saleItem.eventDBID)
      var formData = new FormData();
      formData.append("studentDBID", studentDBID);
      formData.append("eventDBID", saleItem.eventDBID);
      formData.append("orderDate", document.getElementById("orderDate").value);
      formData.append("eventName", saleItem.eventName);
      formData.append("classAmount", saleItem.classAmount);
      formData.append("classDays", saleItem.classDays);
      formData.append("eventPrice", saleItem.eventPrice);
      formData.append("classStartDate", newStartDate);
      formData.append("payment", document.getElementById("payment").value);
      formData.append("amount", document.getElementById("amount").value);
      formData.append("memo", document.getElementById("memo").value);
      formData.append("total", document.getElementById("total").value);
      formData.append("operator", operator);
      formData.append("invoiceID", invoiceID);
      formData.append("token", token);
      formData.append('trial',"true")

      fetch(`${url}/appPost/addNewOrder`, {
        method: "POST",
        body: formData,
        mode: "cors",
      })
        .then((res) => {
          return res.json()

        }).then(() => {
          window.location.href = `/SingleStudentTrialView/${studentDBID}`
        })

      // return window.location.href = `/SingleStudentView/${studentDBID}`
  }




  return (
    <>

      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1" id="title"></h5>
        </div>
        <div className="mt-2">
          <div className="card mt-2 ">
            <div className="card-header alert-info">基本資料區</div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    姓名
                      </span>
                </div>
                <div

                  id="name"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    暱稱
                      </span>
                </div>
                <div

                  id="nickName"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>

            <div className="m-1">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-info"
                    id="validationTooltipUsernamePrepend"
                  >
                    家長
                      </span>
                </div>
                <div

                  id="parent"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"

                />
              </div>
            </div>
   
          </div>

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-warning">訂單資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單日期
                      </span>
                </div>
                <input
                  type="date"
                  id="orderDate"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    專案名稱
                      </span>
                </div>
                <select id="eventSelected" className="custom-select" required 
                onChange={
                  (e)=>{
                    let eventDBID=e.target.value
                    let selectEvent=salesEvent.find(item=>{
                      return item.eventDBID==eventDBID
                    })
                    console.log('eventDBID',eventDBID)
                    console.log(selectEvent)
                    document.getElementById('classAmount').value=selectEvent.classAmount
                    document.getElementById('classDays').value=selectEvent.classDays
                    document.getElementById('eventPrice').value=selectEvent.eventPrice
                    
                    let amount=document.getElementById('amount').value
                    console.log(amount,selectEvent.price)
                    let total=parseInt(amount,"10")*(parseInt(selectEvent.eventPrice,"10"))

                    document.getElementById('total').value=total
                    document.getElementById('taxPrice').innerHTML=`未稅 ${total*0.95} /稅金 ${total*0.05}`

                    let classDays=document.getElementById('classDays').value
                    let startDate=document.getElementById('classStartDate').value
                      let tempDate=new Date(startDate)
                    
                      let classEndDate=new Date(tempDate.setDate(tempDate.getDate() + parseInt(classDays,"10")))
                     
                      document.getElementById('classEndDate').innerHTML = `${classEndDate.getFullYear()}/${classEndDate.getMonth()+1}/${classEndDate.getDate()}`
                  
                }}>
                  <option >請選擇</option>
                  {salesEvent.map((item,index)=>{
                   if(item.forTrial=="true" ){
                    return (
                      <option value={item.eventDBID}>{item.eventName}</option>
                    )
                   }else{
                     return null
                   }
                     
                    
                  
                  })}
                </select>
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂購數量
                      </span>
                </div>
                <input type="number" id="amount" className="custom-select" value="1" disabled/>
            
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    專案堂數
                      </span>
                </div>
              
                <input
                  type="number"
                  id="classAmount"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程天數
                      </span>
                </div>
               
                <input
                  type="number"
                  id="classDays"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    專案單價
                      </span>
                </div>
                <input
                  type="text"
                  id="eventPrice"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                 
                />
              </div>

              <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單總金額
                      </span>
                </div>
                <input
                  type="number"
                  id="total"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  value={0}
                
                />
              </div>
            </div>

            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    未稅/稅金
                      </span>
                </div>
                <div
                  id="taxPrice"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                
                />
              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    發票號碼
                      </span>
                </div>
                <input
                  type="text"
                  id="invoiceID"
                  className="form-control"
                 
                  aria-describedby="validationTooltipUsernamePrepend"
               
                />

              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-warning"
                    id="validationTooltipUsernamePrepend"
                  >
                    付款方式
                      </span>
                </div>
                <select id="payment" className="custom-select" required 
               >
                  <option value="信用卡">信用卡</option>
                  <option value="現金">現金</option>
                  <option value="匯款">匯款</option>
                  <option value="贈課">贈課</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
            </div>
          </div>

          <div className="card  mt-2 " style={{ width: "100%" }}>
            <div className="card-header alert-danger">課程資料區</div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    課程啟用日期
                      </span>
                </div>
                <input
                  type="date"
                  id="classStartDate"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                  required
                  onChange={
                    (e)=>{
                      let classDays=document.getElementById('classDays').value
                      let tempDate=new Date(e.target.value)
                      console.log(tempDate,tempDate.getDate())
                      let classEndDate=new Date(tempDate.setDate(tempDate.getDate() + parseInt(classDays,"10")))
                      console.log(classEndDate,classEndDate.getFullYear(),classEndDate.getMonth()+1,classEndDate.getDate())
                      document.getElementById('classEndDate').innerHTML = `${classEndDate.getFullYear()}/${classEndDate.getMonth()+1}/${classEndDate.getDate()}`
                                         }
                  }
                />
              </div>
            </div>

           
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    體驗課程到期日
                      </span>
                </div>
                <div
                 
                  id="classEndDate"
                  className="form-control"
                  aria-describedby="validationTooltipUsernamePrepend"
                
                />
              </div>
            </div>
            <div className="m-1 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text alert-danger"
                    id="validationTooltipUsernamePrepend"
                  >
                    訂單備註
                      </span>
                </div>
                <input
                  type="text"
                  id="memo"
                  className="form-control"
                 
                  aria-describedby="validationTooltipUsernamePrepend"
               
                />

              </div>
            </div>
          </div>

          <div className="card  mt-2 p-1" style={{ width: "100%" }}>
            <button
              type="button"
              onClick={handelSummit}
              className="btn btn-danger "
            >
              完成付費
                </button>
            <Link to={`/SingleStudentView/${studentDBID}`}>
              <div
                className="btn alert-dark mt-1" style={{ width: "100%" }}
              >
                取消
                  </div>
            </Link>
          </div>

        </div>
      </div>
    </>
  )

};



export default OrderAdd;