import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import bgPic from "../icon/EW2A1883.jpg";
import getUrl from '../function/getUrl'
import checkLogin from "../function/checkLogin.js"
//
const url = getUrl()
const token=sessionStorage.getItem('token')
//新增學員
const StudentADD = (props) => {
checkLogin()
  const [password, setPassword] = useState(null)
  const [passwordChangeFlag,setPasswrodChangeFlag]=useState(false)
  //捕捉 select中被選的option
  function getSelect(e) {
    let selected = [];
    let optionFollow = e;

    for (let i = 0; i < optionFollow.length; i++) {
      if (optionFollow[i].selected) {
        selected.push(optionFollow[i].value);
      }
    }
    return selected;
  }

  function handleBirthdayChangeToPassword(e) {
    const birthdayDate = document.getElementById('birthday').value
    if (!passwordChangeFlag){
      const passwordString = birthdayDate.replaceAll("-", "").substring(4, 8)
      setPassword(passwordString)
    }
    
  }

  function handlePassword(e){
    let value=e.target.value
    setPassword(value)
    setPasswrodChangeFlag(true)
  }
  function checkValue(e) {
    let content = e.target.value;
    console.log("name", content);
    if (content == "") {
      alert(e.target.placeholder);
      e.target.focus();
    }
  }

  function handelSummit(e) {
    var formData = new FormData();
    let name = document.getElementById("name").value;
    //alert('編輯學員 coding 未完成 ');
    console.log("name", name);
    let nickName = document.getElementById("nickName").value;
    let studentType = document.getElementById("studentType").value;
    let birthday = document.getElementById("birthday").value;
    let gender = document.getElementById("gender").value;
    let memo = document.getElementById("memo").value;
    let healthMemo = document.getElementById("healthMemo").value;
    let swimExp = document.getElementById("swimExp").value;
    let parentMemo = document.getElementById("parentMemo").value
    let parent = document.getElementById("parent").value;
    let title = document.getElementById("title").value;
    let phone = document.getElementById("phone").value;
    let password = document.getElementById("password").value;
    let fb = document.getElementById("fb").value;
    let line = document.getElementById("line").value;
    let address = document.getElementById("address").value;
    //let contactGood2swim = document.getElementById("contactGood2swim").value;
    let email = document.getElementById("email").value;
    let wishTime = getSelect(document.getElementById("wishTime").options);
    let introPeople = document.getElementById("introPeople").value;
    formData.append("parentMemo", parentMemo);
    formData.append("address", address);
    formData.append("name", name);
    formData.append("nickName", nickName);
    formData.append("studentType", studentType);
    formData.append("birthday", birthday);
    formData.append("gender", gender);
    formData.append("memo", memo);
    formData.append("healthMemo", healthMemo);
    formData.append("swimExp", swimExp);
    formData.append("parent", parent);
    formData.append("title", title);
    formData.append("phone", phone);
    formData.append("fb", fb);
    formData.append("line", line);
    formData.append("comefrom","櫃台建檔")
    //formData.append("contactGood2swim", contactGood2swim);
    formData.append("email", email);
    formData.append("nickName", nickName);
    formData.append("wishTime", wishTime);
    formData.append("introPeople", introPeople);
    formData.append("password", password);
    formData.append("token",token);
    fetch(`${url}/appPost/addNewStudent`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json()
      }).then(json => {
        console.log(json)
        return window.location.href = `/SingleStudentView/${json.studentDBID}`
      })


  }



  return (
    <>
      <div className="container vw-100 " style={{ maxWidth: "500px" }}>
        <div className="card bg-info text-white p-2">
          <h5 className="m-1">新增學員資料</h5>
        </div>
        <div className="mt-2">
          <form>
            <div className="card mt-2 ">
              <div className="card-header alert-info">學員資料區</div>
              <div className="card-body ">
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        姓名
                      </span>
                    </div>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="請填寫真實姓名"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        小名
                      </span>
                    </div>
                    <input
                      type="text"
                      id="nickName"
                      className="form-control"
                      placeholder="可填寫暱稱、英文姓名"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        生日
                      </span>
                    </div>
                    <input
                      type="date"
                      id="birthday"
                      className="form-control"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                      onChange={handleBirthdayChangeToPassword}
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        性別
                      </span>
                    </div>

                    <select id="gender" className="custom-select" required>
                      <option selected>請選擇...</option>
                      <option value="男">Boy</option>
                      <option value="女">Girl</option>
                    </select>
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        游泳經驗
                      </span>
                    </div>
                    <input
                      type="text-area"
                      id="swimExp"
                      className="form-control"
                      placeholder="請描述有無游泳經驗"
                      aria-describedby="validationTooltipUsernamePrepend"

                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        體況描述
                      </span>
                    </div>
                    <input
                      type="text-area"
                      id="healthMemo"
                      className="form-control"
                      placeholder="有無特殊體況"
                      aria-describedby="validationTooltipUsernamePrepend"

                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2 ">
              <div className="card-header alert-primary">家長 資料區</div>
              <div className="card-body">
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        姓名
                      </span>
                    </div>
                    <input
                      type="text"
                      id="parent"
                      className="form-control"
                      placeholder="請填寫真實姓名"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        稱謂
                      </span>
                    </div>
                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      placeholder="填寫稱謂"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        行動電話
                      </span>
                    </div>
                    <input
                      type="text"
                      id="phone"
                      className="form-control"
                      placeholder="必填項目"
                      aria-describedby="validationTooltipUsernamePrepend"
                      required
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        密碼
                      </span>
                    </div>
                    <input
                      type="text"
                      id="password"
                      className="form-control"
                      placeholder="必填項目"
                      aria-describedby="validationTooltipUsernamePrepend"
                      value={password}
                      onChange={handlePassword}
                      required
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        Line帳號
                      </span>
                    </div>
                    <input
                      type="text"
                      id="line"
                      className="form-control"
                      placeholder="請要求家長提供Line帳號"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        FB帳號
                      </span>
                    </div>
                    <input
                      type="text"
                      id="fb"
                      className="form-control"
                      placeholder="請要求家長提供FB帳號"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="E-mail"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        地址
                      </span>
                    </div>
                    <input
                      type="text"
                      id="address"
                      className="form-control"
                      placeholder="address"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>
                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        期望體驗時段
                      </span>
                    </div>

                    <select
                      id="wishTime"
                      className="custom-select"
                      multiple="multiple"
                    >
                      <option value="平日上午">平日上午 </option>
                      <option value="平日下午">平日下午 </option>
                      <option value="周末上午">周末上午 </option>
                      <option value="周末下午">周末下午 </option>
                    </select>
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        介紹人
                      </span>
                    </div>
                    <input
                      type="text-area"
                      id="introPeople"
                      className="form-control"
                      placeholder="介紹人姓名"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>

                <div className="m-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        備註事項
                      </span>
                    </div>
                    <input
                      type="text-area"
                      id="memo"
                      className="form-control"
                      placeholder=""
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2 " style={{ width: "100%" }}>
              <div className="card-header alert-info">櫃台註記 設定</div>
              <div className="card-body">
                <div className="m-1 ">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-primary"
                        id="validationTooltipUsernamePrepend"
                      >
                        家長備註事項
                      </span>
                    </div>
                    <input
                      type="text-area"
                      id="parentMemo"
                      className="form-control"
                      placeholder="可填寫家長特殊註記事項"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                  </div>
                </div>
                <div className="m-1 ">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text alert-info"
                        id="validationTooltipUsernamePrepend"
                      >
                        身分
                      </span>
                    </div>

                    <select id="studentType" className="custom-select" required>
                      {/* <option >請選擇...</option> */}
                      <option value="體驗" selected>體驗</option>
                      {/* <option value="正式">正式</option> */}

                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="card  mt-2 p-3" style={{ width: "100%" }}>
              <button
                type="button"
                onClick={handelSummit}
                className="btn btn-danger "
              >
                建立
              </button>
              <Link to={`/Home`}>
                <div

                  className="btn alert-dark mt-1" style={{ width: "100%" }}
                >
                  取消
              </div>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default StudentADD;
