
function checkLevel(){
    const level=sessionStorage.getItem("level")

    if (parseInt(level,"10")==1){

        window.location.href="/Home"
    }
    return true


}

export default checkLevel;