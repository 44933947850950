import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getUrl from '../function/getUrl'
import reloadIcon from "../icon/reload.svg";
import searchIcon from "../icon/search.svg";
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay";
const url = getUrl()
const token = sessionStorage.getItem("token")
//快速查詢學生
const CoachClassListThisMonth = (props) => {
  let today = getDay(0, "-");
  // let getMonth=today.substring(0,7)+"-01"
  const [getMonth, setGetMonth] = useState(today.substring(0, 7) + "-01")
  console.log("getMonth", getMonth)
  let param = useParams(); //功能項目
  let staffDBID = param.staffDBID;
  console.log(staffDBID)
  let goLink = "/SingleStudentTrialView/";

  const [classData, setClassData] = useState([])

  const [coachData, setCoachData] = useState([])
  //設計功能切換

  //進度條


  var imgStyle = {
    width: "20px",
    height: "20px",
  };

  const update = async (getMonth) => {
    let data = await fetch(`${url}/appGet/getCoachDashBoard?token=${token}&getMonth=${getMonth}`).then(res => {
      return res.json()
    }).then(json => {
      console.log(json)


      return json
    })
    setCoachData(data)



    let data2 = await fetch(`${url}/appGet/getCoachClassList?staffDBID=${staffDBID}&getMonth=${getMonth}&token=${token}`)
      .then((res) => {
        return res.json();
      }).then(json => {

        return json
      })
    console.log(data)
    setClassData(data2)
  }

  useEffect(async () => {

    update(getMonth)
    //   let data=await fetch(`${url}/appGet/getCoachDashBoard?token=${token}&getMonth=${getMonth}`).then(res=>{
    //     return res.json()
    //   }).then(json=>{
    //     console.log(json)


    //     return json
    //   })
    //   setCoachData(data)



    //   let data2=await fetch(`${url}/appGet/getCoachClassList?staffDBID=${staffDBID}&getMonth=${getMonth}&token=${token}`)
    //   .then((res) => {
    //     return res.json();
    //   }).then(json=>{

    //     return json
    //   })
    //  console.log(data)
    //  setClassData(data2)

  }, []);

  return (
    <>

      <div className="card mt-2" style={{ width: "18rem;" }}>
        <div className="card-body bg-warning">
       
          <h4 className="card-title">教練授課 授課一覽表</h4>
          <small>計算區間為查詢起始日期至該月底</small>
          <div className="input-group input-group-default mb-3" style={{"width":"30%"}}>
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-default">查詢起始日期</span>
            </div>
            <input type="date" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" value={getMonth} onChange={(e) => {
              setGetMonth(e.target.value)
              update(e.target.value)
            }} />
           
          </div>
         
          <div className="card-text alert-light rounded p-2">
            <table className="table ">
              <thead>
                <tr>
                <th className=" px-1 text-center" >姓名</th>
                      <th  className="px-0 text-center" >正課</th>
                      <th  className="px-0 text-center" >正課合格</th>
                      <th  className="px-0 text-center">助教</th>
                      <th  className="px-0 text-center" >合格堂數</th>
                      <th  className="px-0 text-center">進度回饋<br/><small>完成 / 學員人次</small></th>
                </tr>
              </thead>
              <tbody>
                {
                  coachData.map((item, index) => {
                    if (item.staffDBID == staffDBID) {
                        
                      return (
                        <tr >

                          <td className=" px-1 text-center">{item.name}</td>
                          <td className=" px-1 text-center">{item.coachAmount}</td>
                          <td className=" px-1 text-center">{item.feedAmountByDone}</td>
                          <td className=" px-1 text-center">{item.assistantAmount}</td>
                          <td className=" px-1 text-center">{parseInt(item.feedAmountByDone,10)+parseInt(item.assistantAmount,10)/2}</td>
                          <td className=" px-1 text-center">{item.amount} / {item.doneAmount}</td>        
                        </tr>
                      )

                    }

                  })
                }

              </tbody>
            </table>
          </div>

          <div className="card-text alert-light rounded p-2 mt-2">
            <table class="table table-striped">
              <thead>
                <tr className="text-center">
                  <th scope="col">編號</th>
                  <th scope="col">日期</th>
                  <th scope="col">時間</th>
                  <th scope="col">年紀</th>
                  <th scope="col">主題</th>
                  <th scope="col">完課人數</th>
                  <th scope="col">授課別</th>
                  <th scope="col">回饋表填寫</th>
                  <th scope="col">列入計算 </th>
                </tr>
              </thead>
              <tbody className="text-center">
                {classData.map(item => {

                 
                  let coachType = "正課"
                  if (item["coachID"] != staffDBID) {
                    coachType = "助教"
                  }

                  let Mark=" "
                  if (item["done"]>0 && coachType=="正課") {
                    if (item["amount"]==item["done"]){
                      Mark="⭐"
                    }

                  }else if (item["done"]>3 && coachType=="助教"){
                    Mark="⭐"
                  }
                  let single = (
                    <>
                      <tr
                        onClick={() => {
                          window.location.href = `/SingleClassViewForCoach/${item["classDBID"]}`;
                        }}
                        className="text-muted"
                        style={{ textDecoration: "none" }}
                      >
                        <td>{item["classDBID"]}</td>
                        <td>{item["classDate"]}</td>
                        <td>{item["classTime"]}</td>
                        <td>{item["age"]}</td>
                        <td>{item["classProcess"]}</td>
                        <td>{item["done"]}</td>
                        <td>{coachType}</td>
                        <td>{item["amount"]}</td>
                        <td>{Mark}</td>
                      </tr>
                    </>
                  )

                  return single
                })


                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="text-right mt-1 p-2 text-muted">⭐: ① 正課：1人以上且完成回饋填寫   ② 助教：4人以上  </div>
    </>
  );
};

export default CoachClassListThisMonth;
