import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import fileIcon from "../icon/file.svg";
import lotIcon from "../icon/sliders.svg";

const FunBar = (props) => {
  let studentDBID = props.studentDBID;
  let studentEdit = "/studentEdit/" + studentDBID;
  let newOrder = "/newOrder/" + studentDBID;
  let makeClassRecord = "/makeClassRecord/" + studentDBID;
  let makeLotClassReocrd = "/makeLotClassReocrd/" + studentDBID;
  const version = "V 3.0.1 ";
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };
  //menu Bar 元作
  return (
    <nav className="navbar navbar-light bg-secondary rounded py-1 ">
     
      <div>
        <Link className="navbar-brand" to={`/MakeRecordFromGetClassList/${studentDBID}`} style={{ textAlign: "center" }}>
          <img src={lotIcon} style={imgStyle} alt="Home" />
          <span className="text-light ml-1" style={fontStyle}>
            {" "}
            大量排課
          </span>
        </Link>
      </div>
      <div>
        <Link className="navbar-brand" to={`/allClassCancel/${studentDBID}`} style={{ textAlign: "center" }}>
          <img src={lotIcon} style={imgStyle} alt="Home" />
          <span className="text-light mr-1" style={fontStyle}>
            {" "}
            一鍵取消
          </span>
        </Link>
      </div>

      <div>
        <Link
          className="navbar-brand"
          to={`/singleStudentViewForFreePractice/${studentDBID}`}
          style={{ textAlign: "center" }}
        >
          <img src={fileIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            自由練習查詢
          </span>
        </Link>
      </div>

      <div>
        <Link className="navbar-brand" to={`/orderAdd/${studentDBID}`} style={{ textAlign: "center" }}>
          <img src={saleIcon} style={imgStyle} alt="Home" />
          <span className="text-light mr-1" style={fontStyle}>
            {" "}
            新增課程訂單
          </span>
        </Link>
      </div>
      
      <div>
        <Link
          className="navbar-brand"
          to={studentEdit}
          style={{ textAlign: "center" }}
        >
          <img src={pencilIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            編輯學員
          </span>
        </Link>
      </div>

      <div>
        <Link
          className="navbar-brand"
          to={`/singleStudentViewForActionHistory/${studentDBID}`}
          style={{ textAlign: "center" }}
        >
          <img src={fileIcon} style={imgStyle} alt="Home" />
          <span className="text-light" style={fontStyle}>
            {" "}
            學習進度
          </span>
        </Link>
      </div>
    </nav>
    
  );
};

export default FunBar;
