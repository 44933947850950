function birthdayToAge(birthday,mark){

    //生日換算 年紀 適合Age
	//mark: 日期分隔線
	try {

	
		let markNum=birthday.indexOf("/")
		//console.log(markNum)
		if (markNum == -1){
			mark="-"
		}else{
			mark="/"
		}
		//console.log(mark)
		let ageArray=birthday.split(mark)
		let year=ageArray[0]
		let mon=ageArray[1]
		let day=ageArray[2]
		let time=new Date()
		let nowYear=time.getFullYear()
		let nowMon=time.getMonth()+1
		let nowDay=time.getDate()
		
		let ageMonthAmount=(nowYear-year)*12+(nowMon-mon)+1
		let ageYear=(ageMonthAmount-(ageMonthAmount)%12)/12
		let ageMon=(ageMonthAmount)%12
		let age=ageYear+"Y"+ageMon+"M"
		let classAge=""
		
		switch (ageYear){
			case 0:
				if(ageMon >6 ){
					classAge="6-12M"
				}else if(ageMon >2 ){
					classAge="3-6M"
				}else  {
					classAge="2M"
				}
				break;
			case 1:
				classAge="1-2Y"
				break;
			case 2:
				classAge="2-3Y"
				break;
			case 3:
			case 4:
			case 5:
			case 6:
				classAge="3-7Y"
				break;
			case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
				classAge="7-12Y"
				break;
		}
		if (age=="NaNYNaNM"){
            age="不詳"
            classAge=""
        }
    
    return [age,classAge]
}catch (e){
	return ["不詳","不詳"]
}
}

export default birthdayToAge;