import React ,{useState,useEffect,useCallback,updateState} from 'react';
import { Link ,useParams} from 'react-router-dom';
import birthdayToAge from '../function/birthdayToAge.js'
import getUrl from '../function/getUrl.js'
import reloadIcon from '../icon/reload.svg'
import searchIcon from '../icon/search.svg'
import checkLogin from "../function/checkLogin.js"
const url=getUrl()
const token=sessionStorage.getItem("token")
//一日課表元件
const GetDailyTableComponent=(props)=>{
checkLogin()
    let getDate=props.getDate.replaceAll("-","/")
   //ios New Data()只能使用 ‘2020/12/01'格式 不可使用 -
    var  dateWeek= new Date(getDate).getDay()
    console.log("dateWeek",dateWeek)
    var day_list = ['日', '一', '二', '三', '四', '五', '六'];
    let staff=[]

        var week  = day_list[dateWeek]

    const [pickDate,setPickDate]=useState(getDate)

    const [classList,setClassList]=useState(<p className="text-info">載入中...</p>)
    
    const fetCoachData = () => {
        return fetch(`${url}/appGet/getStaffData?token=${token}`).then((res) => {
          return res.json()
        }).then(json => {
          staff=json
          return json
         
        })
      }
      
      fetCoachData()

    function getClassList(getDate){
        return new Promise((resolve, reject) =>{
       

        fetch(`${url}/appGet/getDailyClassTableAmount?date=${getDate}&token=${token}`).then((res)=>{
            return res.json();
        }).then(json=>{
          
            
           
            json.sort((a,b)=>{
  
                var nameA = a.classTime; 
                var nameB = b.classTime; 
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
              
 
              return 0;
              })
              
            //sessionStorage.setItem('dailyClassList',JSON.stringify(json))
            resolve (json)
        })
    })
    }




  
    function classPart(getDate){
            getClassList(getDate).then((json)=>{
                console.log(json)
           let classArray=[]
            for (let x in json){
                
                let CardCss="card text-white mt-1 mb-1 border-0 "
                let CardBodyCss="card P-0 m-1 "
              switch (json[x]['age']){
                case '3-6M':
                    CardCss+="bg-primary"
                    CardBodyCss+="alert-primary"
                    break;
                case '6-12M':
                    CardCss+="bg-secondary"
                    CardBodyCss+="alert-secondary"
                    break;
                case '1-2Y':
                    CardCss+="bg-danger"
                    CardBodyCss+="alert-danger"
                    break;
                case '2-3Y':
                    CardCss+="bg-info"
                    CardBodyCss+="alert-info"
                    break;
                case '3-7Y':
                    CardCss+="bg-warning text-dark"
                    CardBodyCss+="alert-warning"
                    break;
                case '7-12Y':
                    CardCss+="bg-success"
                    CardBodyCss+="alert-success"
                    break;
                default:
                    CardCss+="bg-light text-dark"
                    CardBodyCss+="alert-light "
                    break;
              }

              let studentArray=json[x]['studentList']
              studentArray.sort((a,b)=>{
  
                var nameA = a.state; 
                var nameB = b.state; 
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
              
 
              return 0;
              })
              let studentShow=[]
       

              for (let q in studentArray){
                  // studentState="取消" 就顯示
                if (studentArray[q].studentState !="取消"){
                    studentShow.push(<div className="m-0"><small > { studentArray[q].name} {studentArray[q].studentState }</small></div>)
                }
                
                }

                //console.log("staff",staff)
                staff.map((item,index)=>{
                  //console.log("item.staffDBID",item.staffDBID,"json[x][coachID]",json[x]["coachID"])
                  if (item.staffDBID==json[x]["coachID"]){
                    json[x]["coach"]=item.name
                  }
                  if (item.staffDBID==json[x]["assistantID"]){
                    json[x]["assistant"]=item.name
                  }
                })
                
                let toClass=`/SingleClassView/`+json[x]["classDBID"]
              
                let singleClass=
                <Link style={{textDecoration:"none"}} to={toClass} >
                    <div className={CardCss}  >
                         <h6 className="card-header">{json[x]['classTime']} {json[x]['age']} </h6>
                       
                         <small className="card-text">限:{json[x]['limit']}  正:{json[x]['booking']}   補:{json[x]['backup']}</small>
                         <small className="card-text m-0 ">教練:{json[x]['coach']}/助教：{json[x]['assistant']}</small>
                        <div className={CardBodyCss} key={json[x]['classDBID']}>
                       
                          
                            <div >{studentShow}</div>
                        </div>
                    
                    </div>
                   </Link>

            classArray.push(singleClass)
    
            }
            
            if (classArray.length==0){
                setClassList(<p className="text-muted">本日無課</p>)
            }else{
                setClassList(classArray)
            }
       
           

            })
          
            
        
       }


     
 


useEffect(()=>{
    classPart(getDate)
},[])

    return (
        <>
         
         <div className=" border rounded p-0 text-center m-0">
                <div className=" alert-info  p-1">
                <h6 className="  p-1 ">{pickDate}<br/> ({week}) </h6>
                </div>
                <div className="p-1">
                {classList}
                </div>
           </div>
                    
         
            

        </>
    )
    
}

export default GetDailyTableComponent;