
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import birthdayToAge from "../function/birthdayToAge.js"
// import checkStudent from '../function/checkStudent'


import person from "../icon/person.svg"

const url = getUrl()
const token = sessionStorage.getItem("token")
const StudentCard = (props) => {
    //確認系統storage中有指定學員資料
    // checkStudent()
    console.log("props.studentDBID", props.studentDBID)
    let studentDBID = props.studentDBID
    const today = getDay(0, "-")
    const cancelDay = getDay(3, "-")

    const student = JSON.parse(sessionStorage.getItem("student"))
    const [studentName, setStudentName] = useState(null)
    const [classBalance, setClassBalance] = useState(0)
    const [classTotal, setClassTotal] = useState(0)
    const [classBooking, setClassBooking] = useState(0)
    const [classBalanceForBooking, setClassBalanceForBooking] = useState(0)
    const [classDone, setClassDone] = useState(0)
    const [classNoshow, setClassNoshow] = useState(0)
    const [classData, setClassData] = useState([])
    const [studentClassLastDate, setStudentClassLastDate] = useState(null)
    const [presentAge, setPresentAge] = useState(null)
    const [classAge, setClassAge] = useState(null)
    const [cssState, setCssState] = useState(null)
    
    useEffect(() => {
        fetch(`${url}/appGet/singleStudentView?studentDBID=${studentDBID}&token=${token}`)
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                return json

            }).then(json => {
                console.log(json)
                setClassTotal(json.classTotal)
                setClassBalance(json.classBalance)
                setClassBooking(json.classBooking)
                setClassBalanceForBooking(json.classBalanceForBooking)
                setClassDone(json.classDone)
                setClassNoshow(json.classNoshow)
                setStudentName(json.name)
                setStudentClassLastDate(json.studentClassLastDate)
                let tempAge = birthdayToAge(json.birthday)
                // setBirthdayToAge(tempAge)
                setPresentAge(tempAge[0])
                setClassAge(tempAge[1])
                console.log(tempAge)
                if (json.gender == "女") {
                    setCssState("card-body alert-danger")
                } else {
                    setCssState("card-body alert-info")
                }
            });

    }, [])

    return (
        <>
            <div className="card mb-2 text-left rounded-bottom mt-1"  >

                <div className={cssState}>
                    <div className="row">

                        <div className="col text-left">
                            <h3 >{studentName} </h3>
                           
                        </div>
                        <div className="col text-right">

                            <div className="ml-5"><span className="font-weight-bolder " > <h4>期限 :{studentClassLastDate}</h4></span> </div>
                        </div>


                    </div>
                    <hr />
                    <div>
                        <div className="row">
                        
                            <div className="col ">
                                年紀：{presentAge}
                            </div>
                        
                            <div className="col ">
                                缺課數：{classNoshow} 堂
                            </div>
                            
                            <div className="col ">
                                餘課數：{classBalance} 堂
                            </div>
                            <div className="col ">
                                已劃位：{classBooking} 堂
                            </div>
                            <div className="col ">
                                待劃位：{classBalanceForBooking} 堂
                       </div>
                        </div>

                    </div>

                </div>
            </div>



        </>
    );
}




export default StudentCard;