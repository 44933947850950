import { get } from "request";
import getUrl from "../function/getUrl.js"

//取得課程資料 

function getClassData(classDBID) {
    console.log(classDBID)
    const url = getUrl()
    console.log(url)
    
    const result=fetch(`${url}/singleClassView?classDBID=${classDBID}`)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            console.log(data)
            return data
            

        })

     return result
    }

export default getClassData;