import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import personIcon from "../icon/person.svg";
import getDay from "../function/getDay.js"
import checkLogin from "../function/checkLogin.js"
import coachIcon from "../icon/coach.svg"
import report from "../icon/contract.svg"
import fingerTap from "../icon/tap.svg"
const HeaderBar = (props) => {
  const name = sessionStorage.getItem('name')
  const timecode = sessionStorage.getItem("timeCode")
  const operator = sessionStorage.getItem("staffDBID")
  const now = Date.now()
  const version = "V 3.0.1 ";
  const today = getDay(0, "-")
  const level = sessionStorage.getItem("level")
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };




  let GetDailyTableURL = `/GetDailyTable/${today}`

  let GetWeekTableURL = `/GetWeekTable/${today}`


  if (now > timecode || timecode == null) {
    //時間過期
    return (
      <nav className="navbar navbar-light navbar-expand-lg  bg-dark ">
        <div className="mr-auto">
          <Link className="navbar-brand" to="/Login" style={{ textAlign: "center" }}>
            <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} />
          </Link>
        </div>
        <div className="mr-right">
          <Link className="navbar-brand" to="/login" style={{ textAlign: "center" }}>
            <img src={HomeSVG} style={imgStyle} alt="Home" />
            <span className="text-light" style={fontStyle}>
              {" "}
              登入
            </span>
          </Link>
        </div>


      </nav>
    )
  }


  return (

    <>
      <nav className="navbar navbar-light navbar-expand-lg  bg-dark ">

        {/* <div className="mr-auto">
          <div className="navbar-brand btn  btn-dark" onClick={()=>{ window.location.href="/"}} style={{ textAlign: "center" }}>
            <img src={HomeSVG} style={imgStyle} alt="Home" />
            <span className="text-light" style={fontStyle}>
              {" "}
              Home
            </span>
          </div>
        </div> */}
        {/* <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
         

          




     

          <div className="ml-auto">
            <div className=" btn btn-dark " onClick={()=>{window.location.href="/GetDailySignTable"}}>
            <img src={fingerTap} width="25pt" alt="eject" /><span className="text-light" style={fontStyle}> 好好游學員 線上簽到</span>
            </div>
            
          </div>


       
{/* 
          <div className="mr-auto">
          <div className=" btn btn-dark" onClick={()=>{window.location.href="https://member.good2swim.com/trial"}}>
              <img src={starIcon} style={imgStyle} alt="eject" />
              <span className="text-light" style={fontStyle}>
                體驗報名
            </span>
            </div>
          </div> */}


        </div>
      </nav>
    </>
  );
};

export default HeaderBar;
